import React, {createContext, Dispatch, SetStateAction, useContext, useState} from 'react';
import Toast from '../components/common/toast';

interface ToastType {
  toastType: 'success' | 'error' | 'warning';
  toastTitle: string;
  toastBody: string;
}

interface ToastContextType {
  toastData: ToastType;
  setToastData: Dispatch<SetStateAction<ToastType>>;
  setOpenToast: Dispatch<SetStateAction<boolean>>;
  openToast: boolean;
}

const ToastContext = createContext<ToastContextType>({
  toastData: {
    toastType: 'success',
    toastBody: '',
    toastTitle: ''
  },
  setToastData: () => {},
  setOpenToast: () => {},
  openToast: false
});

export const ToastContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [toastData, setToastData] = useState<ToastType>({
    toastType: 'success',
    toastBody: '',
    toastTitle: ''
  });
  const [openToast, setOpenToast] = useState(false);
  return (
    <ToastContext.Provider value={{toastData, setToastData, openToast, setOpenToast}}>
      <>
        {children}
        <Toast
          open={openToast}
          setOpen={setOpenToast}
          type={toastData.toastType}
          message={{
            title: toastData.toastTitle,
            body: toastData.toastBody
          }}
        />
      </>
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToastContext must be used within a useToastContext');
  }
  return context;
};
