import styles from './styles.module.scss';
import {Box, Button, CircularProgress, Dialog, Paper, Tooltip, Typography} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useToastContext} from 'contexts/ToastContext';
import TextArea from 'components/common/text-area';
import SelectDropdown from '../../selectDropdown';
import {propertyStatusNames} from '../../../../constants';
import {PropertyStatus} from '../../../../types';
import {useParams} from 'react-router-dom';
import {archiveProperty} from '../../../../api/base';

const Archive: React.FC<{
  setOpen: (value: boolean) => void;
  open: boolean;
  propertyId: string;
  refetch: any;
  comment: string;
}> = ({setOpen, open, propertyId, refetch, comment}) => {
  const {setToastData, setOpenToast} = useToastContext();
  const {filter: filterName = ''} = useParams();
  const [loading, setLoading] = useState(false);
  const {control, handleSubmit, watch, register, setValue} = useForm({
    mode: 'onSubmit'
  });

  const status: string = watch('status', '');

  const onSubmit = async (data: Record<string, string>) => {
    if (!propertyId || !data.status) {
      return;
    }
    setLoading(true);
    const response = await archiveProperty({comment: data.comment || '', property_id: propertyId, status: data.status});
    setToastData({
      toastBody: response.message,
      toastTitle: response.success ? 'Успіх' : 'Помилка',
      toastType: response.success ? 'success' : 'error'
    });
    setValue('comment', '');
    setValue('status', '');
    setOpenToast(true);
    setOpen(false);
    setLoading(false);
    refetch();
  };

  const items = useMemo(() => {
    return [
      {label: propertyStatusNames[PropertyStatus.RENTED_BY_US], value: PropertyStatus.RENTED_BY_US},
      {label: propertyStatusNames[PropertyStatus.RENTED_BY_OTHERS], value: PropertyStatus.RENTED_BY_OTHERS}
    ];
  }, []);

  useEffect(() => {
    setValue('comment', comment || '');
  }, [comment]);

  return (
    <>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        className={styles.dialogWrapper}
      >
        <Paper component={'form'}>
          <Typography variant={'h5'} sx={{mb: '24px'}}>
            <strong>Додати в архів</strong>
          </Typography>
          <Box className={styles.comment}>
            <SelectDropdown
              control={control}
              register={register}
              items={items}
              label={''}
              name={'status'}
              size={'small'}
              placeholder={'Виберіть статус'}
            />
          </Box>
          <Box className={styles.comment}>
            <TextArea
              disabled={loading}
              control={control}
              register={register}
              label={'Додайте коментар'}
              onChange={() => {}}
              name={'comment'}
              rules={{
                required: {value: true, message: "Поле обов'язкове"},
                minLength: {
                  value: 3,
                  message: 'Мінімум 3 символи.'
                }
              }}
            />
          </Box>

          <Box className={styles.btns}>
            <Button
              type={'button'}
              variant="text"
              onClick={() => {
                setOpen(false);
                setValue('comment', '');
              }}
            >
              <strong>Скинути</strong>
            </Button>
            <Tooltip title={!Boolean(status) && !loading && 'Виберіть статус'}>
              <span>
                <Button
                  className={styles.button}
                  variant={'contained'}
                  color={'primary'}
                  type={'button'}
                  onClick={handleSubmit(onSubmit)}
                  disabled={!Boolean(status) || loading}
                >
                  {loading ? <CircularProgress size={'24px'} /> : <strong>В архів</strong>}
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Paper>
      </Dialog>
    </>
  );
};

export default Archive;
