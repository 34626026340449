import React from 'react';
import {filterFields} from 'mock';
import {Grid} from '@mui/material';
import styles from '../styles.module.scss';
import {InputSizes} from '../../inputField';
import SelectDropdown from '../../selectDropdown';
import {getNormalisedOptions} from 'helpers';
import {propertySourceNames} from 'constants/index';

const SourceSelect: React.FC<{control: any; register: any; gridSize: number}> = ({control, register, gridSize = 6}) => {
  const config = filterFields['source'];

  return (
    <Grid item xs={gridSize} className={styles.row}>
      <SelectDropdown
        control={control}
        register={register}
        label={config.label}
        name={'source'}
        placeholder={config.placeholder}
        onChange={() => {}}
        size={config.size as InputSizes}
        items={getNormalisedOptions(propertySourceNames)}
      />
    </Grid>
  );
};

export default SourceSelect;
