import {useQuery} from 'react-query';
import {getDistrict, getResidentialComplex} from '../api/filters';

const useResidentialComplex = () => {
  const {data: residentialComplex = [], isLoading: isResidentialComplexLoading} = useQuery(
    'locations/residentialComplex',
    getResidentialComplex
  );

  return {residentialComplex, isResidentialComplexLoading};
};

export default useResidentialComplex;
