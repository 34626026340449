import {OfferType, PropertyLabels, PropertyLabelsType} from '../../../types';
import React from 'react';
import {Tooltip} from '@mui/material';
import Label from '../label';
import {labelNames, offerTypeNames} from '../../../constants';

const ListItemLabels: React.FC<{labels: PropertyLabels[]; offerType: string; isMergeItem?: boolean}> = ({
  labels,
  offerType,
  isMergeItem = false
}) => {
  const excludedLabels = [PropertyLabelsType.PRICE_LOWER, PropertyLabelsType.PRICE_HIGHER];
  const renderedLabels = labels?.filter((label) => !excludedLabels.includes(label.label.alias));
  return (
    <>
      {renderedLabels?.map((label, index) => {
        return (
          <Tooltip title={label?.comment || ''} key={index}>
            <span>
              <Label
                title={labelNames[label.label.alias]}
                variant={
                  label.label.alias === PropertyLabelsType.OWNER || label.label.alias === PropertyLabelsType.MAYBE_OWNER
                    ? 'main'
                    : 'label'
                }
              />
            </span>
          </Tooltip>
        );
      })}
      {offerType === OfferType.RENT && (
        <Label title={offerTypeNames[OfferType.RENT]} variant={isMergeItem ? 'label' : 'default'} />
      )}
    </>
  );
};

export default ListItemLabels;
