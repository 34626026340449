import {Box, Button, Link, Typography} from '@mui/material';
import {OpenInNew as OpenInNewIcon} from '@mui/icons-material';
import React from 'react';
import styles from './styles.module.scss';
import {ReactComponent as BuildingIcon} from 'svg-icons/building.svg';
import {EstateSource, EstateType} from 'types';
import {convertTimeAgo} from 'helpers';
import classNames from 'classnames';

const Header: React.FC<{sources: EstateSource[]; estateType: EstateType; title: string; createdAt: string}> = ({
  sources,
  estateType,
  title,
  createdAt
}) => {
  const source = Array.isArray(sources) ? sources.find((source) => source.primary) : sources;
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.inner}>
        {!!source && estateType === EstateType.APARTMENT && (
          <Button className={styles.btn} sx={{color: 'text.primary'}}>
            <BuildingIcon />
          </Button>
        )}
        <Typography variant="h5" className={styles.inner}>
          {title}
        </Typography>
        {!!source?.link && (
          <Link target="_blank" href={source.link} className={styles.link2} sx={{mb: '-4px'}}>
            <OpenInNewIcon />
          </Link>
        )}
      </Box>
      <Box className={classNames(styles.inner, styles.noWrap)}>
        {!!source?.created_at && (
          <>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              Парсінг:
            </Typography>
            <Typography variant={'caption'} className={styles.noWrap}>
              {convertTimeAgo(createdAt)}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Header;
