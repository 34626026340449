import {Box, useTheme} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import styles from './styles.module.scss';
import {ReactComponent as NextIcon} from 'svg-icons/nextIcon.svg';
import {ReactComponent as PrevIcon} from 'svg-icons/prevIcon.svg';
import {useMemo, useState} from 'react';
import classNames from 'classnames';

export const ImageCarousel = ({imageList, nativeStyles = ''}) => {
  const theme = useTheme();
  const [loadErrorCount, setLoadErrorCount] = useState(0);
  const onImageError = (event) => {
    if (loadErrorCount > 1) {
      event.target.src = '';
      return;
    }
    event.target.src = '/imageDefault.png';
    setLoadErrorCount((state) => state + 1);
  };

  const DefaultSettingsT = useMemo(() => {
    return {
      animation: 'fade',
      navButtonsAlwaysVisible: true,
      cycleNavigation: true,
      fullHeightHover: true,
      swipe: true,
      autoPlay: false,
      indicatorIconButtonProps: {
        style: {
          width: '4px',
          height: '4px',
          padding: '5px',
          margin: '0',
          color: theme.palette.text.customGray
        },
        className: 'caruoselIndicator'
      },
      activeIndicatorIconButtonProps: {
        style: {
          color: theme.palette.text.primary
        }
      }
    };
  }, [theme]);

  return (
    <Box className={styles.box}>
      <Carousel
        NextIcon={<NextIcon />}
        PrevIcon={<PrevIcon />}
        containerClasses={'containerCarousel'}
        navButtonsProps={{
          style: {
            height: 30,
            width: 30,
            padding: 0,
            margin: 0,
            backgroundColor: 'transparent'
          }
        }}
        {...DefaultSettingsT}
      >
        {imageList.map((image, i) => (
          <Box className={classNames(styles.wrapper, nativeStyles)} key={i}>
            <img className={styles.item} src={image.url} alt={image || 'image'} onError={onImageError} />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};
