import {EstateCity, IStreet, PropertyAdmin, RawItem, ResponseWithPagination} from '../types';
import api from './server';
import {API_ROUTES} from '../constants';

export async function getDistrict(): Promise<RawItem[]> {
  const response = await api.get(API_ROUTES.DISTRICTS);
  return response.data.data;
}

export async function getStreetStreets(): Promise<IStreet[]> {
  const response = await api.get(API_ROUTES.STREETS);
  return response.data.data;
}

export async function getCities(): Promise<EstateCity[]> {
  const response = await api.get(API_ROUTES.CITIES);
  return response.data.data;
}

export async function getResidentialComplex(): Promise<RawItem[]> {
  const response = await api.get(API_ROUTES.RESIDENTIAL_COMPLEXES);
  return response.data.data;
}

export async function getBuildingTypes(): Promise<RawItem[]> {
  const response = await api.get(API_ROUTES.BUILDING_TYPES);
  return response.data.data;
}

export async function getSearchAdmins(params: {only_active: boolean; name: string}): Promise<PropertyAdmin[]> {
  const response = await api.get(API_ROUTES.ADMINS_SEARCH, {params});
  return response.data.data;
}

export async function getAdmins(params: {cursor: string}): Promise<ResponseWithPagination<PropertyAdmin[]>> {
  const response = await api.get(API_ROUTES.ADMINS, {params});
  return response.data;
}
