import React, {useMemo, useState} from 'react';
import {filterFields} from '../../../../mock';
import {Box, Button, Divider, Grid, IconButton, Tooltip} from '@mui/material';
import styles from './styles.module.scss';
import InputField, {InputSizes} from '../../inputField';
import {getNormalisedOptions} from '../../../../helpers';
import {propertyContactRole} from '../../../../constants';
import InputMask from '../../inputMask';
import ControlPointSharpIcon from '@mui/icons-material/ControlPointSharp';
import {ContactRole, RawEstateContacts} from '../../../../types';
import AddIcon from '@mui/icons-material/Add';

const ContactInfo: React.FC<{
  initialData: RawEstateContacts[];
  control: any;
  register: any;
  setValue: (name: string, value: any) => void;
  watch: any;
}> = ({initialData, control, register, setValue, watch}) => {
  const [{propertyContacts, formCount}, setPropertyContacts] = useState<{
    propertyContacts: RawEstateContacts[];
    formCount: number;
  }>({
    propertyContacts: initialData,
    formCount: 0
  });
  const [inputCount, setInputCount] = useState<number>(0);

  const onAddNewNumberClick = (
    contact: RawEstateContacts,
    count: number,
    newPhoneNumber: string,
    clearFn: () => void
  ) => {
    setPropertyContacts((state) => {
      const filteredContact = state.propertyContacts.map((item) => {
        if (item.id === contact.id) {
          setValue(`contact_phone_number_${count}_${newPhoneNumber}_${contact.id}`, newPhoneNumber);
          return {
            ...item,
            name: contact.name,
            role: contact.role,
            phone_numbers: [
              ...contact.phone_numbers,
              {
                id: count.toString(),
                phone_number: newPhoneNumber,
                linked: true
              }
            ]
          };
        }
        return item;
      });
      return {
        formCount: state.formCount,
        propertyContacts: [...filteredContact]
      };
    });
    setInputCount((state) => {
      return state + 1;
    });
    clearFn();
  };

  const onAddContactClick = () => {
    setPropertyContacts((state) => {
      return {
        formCount: +state.formCount + 1,
        propertyContacts: [
          ...state.propertyContacts,
          {
            id: formCount.toString(),
            name: '',
            role: ContactRole.UNKNOWN,
            phone_numbers: [],
            comment: null
          }
        ]
      };
    });
  };

  return (
    <>
      {propertyContacts.map((contact, index) => {
        const nameConfig = filterFields['name'];
        const roleConfig = filterFields['role'];
        const NameWidget = nameConfig.Widget;
        const RoleWidget = roleConfig.Widget;
        const FakeTag = React.Fragment;
        const newPhoneNumber = watch(`new_phone_numbers_${formCount}_${index}_${contact.id}`);
        return (
          <FakeTag key={`contact_name_${index}`}>
            <Grid item xs={6} className={styles.row}>
              <NameWidget
                control={control}
                register={register}
                label={nameConfig.label}
                name={`contact_name_${contact.id}`}
                placeholder={nameConfig.placeholder}
                onChange={() => {}}
                size={nameConfig.size as InputSizes}
              />
            </Grid>
            <Grid item xs={6} className={styles.row}>
              <RoleWidget
                control={control}
                register={register}
                label={roleConfig.label}
                name={`contact_role_${contact.id}`}
                placeholder={roleConfig.placeholder}
                onChange={() => {}}
                size={roleConfig.size as InputSizes}
                items={getNormalisedOptions(propertyContactRole)}
              />
            </Grid>
            {contact.phone_numbers.map((phoneNumber) => {
              const config = filterFields['phone_numbers'];
              const Widget = config.Widget;
              return (
                <Grid item xs={6} className={styles.row} key={`name_${phoneNumber.phone_number}`}>
                  <Widget
                    control={control}
                    label={config.label}
                    name={`contact_phone_number_${phoneNumber.id}_${phoneNumber.phone_number}_${contact.id}`}
                    placeholder={config.placeholder}
                    onChange={() => {}}
                    size={config.size as InputSizes}
                  />
                </Grid>
              );
            })}
            <Grid item xs={6} className={styles.row} sx={{position: 'relative'}}>
              <Box sx={{width: 1}}>
                <InputMask
                  control={control}
                  label={`Додатковий номер телефону`}
                  name={`contact_new_phone_numbers_${formCount}_${index}_${contact.id}`}
                  placeholder={'Додати новий номер'}
                  onChange={() => {}}
                  size={'medium'}
                />
                <Tooltip title={!newPhoneNumber ? 'Заповніть поле Номер' : ''} placement="top">
                  <Box className={styles.buttonWrapper}>
                    <IconButton
                      className={styles.button}
                      sx={{color: 'background.info', bgcolor: 'background.default'}}
                      disabled={!newPhoneNumber}
                      onClick={() => {
                        onAddNewNumberClick(contact, inputCount, newPhoneNumber, () => {
                          setValue(`new_phone_numbers_${formCount}_${index}_${contact.id}`, '');
                        });
                      }}
                      type={'button'}
                    >
                      <ControlPointSharpIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12} className={styles.row} sx={{w: 1}}>
              <Box>
                <InputField
                  control={control}
                  name={`contact_comment_${contact.id}`}
                  register={register}
                  label={contact.comment ? 'Коментар до обʼєкту' : 'Додати Коментар'}
                  onChange={() => {}}
                  size={'medium'}
                  sx={{bgcolor: 'background.label', borderRadius: '8px'}}
                />
              </Box>
            </Grid>

            {index !== propertyContacts.length - 1 ? (
              <Divider sx={{width: 'calc(100% + 16px)', height: '1px', pt: '12px'}} />
            ) : (
              <Box sx={{width: '100%', height: '10px'}} />
            )}
          </FakeTag>
        );
      })}
      <Button
        variant={'outlined'}
        type={'button'}
        className={styles.addContact}
        sx={{bgcolor: 'background.default'}}
        onClick={onAddContactClick}
      >
        <AddIcon />
        Додати контакт
      </Button>
    </>
  );
};

export default ContactInfo;
