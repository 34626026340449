import {useQuery} from 'react-query';
import {ILabelListItem} from 'types';
import {getLabels} from 'api/labels';

const useLabels = (): {labelsList: ILabelListItem[]; isLabelLoading: boolean} => {
  const {data: labelsList = [], isLoading: isLabelLoading} = useQuery('labels', getLabels, {
    retry: false
  });
  return {labelsList, isLabelLoading};
};

export default useLabels;
