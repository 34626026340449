import api from './server';
import {API_ROUTES} from '../constants';
import {IPropertyMedia, IPropertyMediaResponse} from '../types';

export const getPropertyMedia = async (propertyId: string, requestUrl: string): Promise<IPropertyMediaResponse> => {
  const response = await api.get(`${requestUrl}/${propertyId}/media`);
  return response.data.data;
};

// @ts-ignore
export const postTemporaryMedia = async (media: FormData): Promise<IPropertyMedia[]> => {
  try {
    const response = await api.post(API_ROUTES.TEMPORARY_MEDIA, media);
    return response.data.data;
  } catch (error: any) {
    if (error.handleError) {
      throw error;
    }
  }
};
