import React, {useState} from 'react';
import {filterFields} from 'mock';
import {Box, Button, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Tooltip} from '@mui/material';
import styles from './styles.module.scss';
import {formatToLocalTime, getNormalisedOptions} from 'helpers';
import {EstateSource, FormFields, PropertySourceType} from 'types';
import AddIcon from '@mui/icons-material/Add';
import {ReactComponent as CloseIcon} from 'svg-icons/roundClose.svg';
import {Controller} from 'react-hook-form';
import {propertySourceNames, urlPattern} from 'constants/index';
import {useToastContext} from '../../../../contexts/ToastContext';

const mockSources = [
  {
    id: '-1',
    source: {
      type: '',
      name: ''
    },
    source_id: '',
    link: '',
    posted_at: '',
    primary: false,
    created_at: ''
  }
];

const SourcesInfo: React.FC<{
  initialData: EstateSource[];
  control: any;
  register: any;
  setValue: (name: string, value: any) => void;
  watch: any;
  unregister: any;
}> = ({initialData, control, register, watch, unregister, setValue}) => {
  const [{propertySource, formCount}, setPropertySource] = useState<{
    propertySource: EstateSource[];
    formCount: number;
  }>({
    propertySource: initialData || mockSources,
    formCount: 0
  });
  const {setToastData, setOpenToast} = useToastContext();

  const primarySourceId = watch('source_primary', '');

  const onAddContactClick = () => {
    setPropertySource((state: any) => {
      return {
        formCount: +state.formCount + 1,
        propertySource: [
          ...state.propertySource,
          {
            id: formCount.toString(),
            link: null,
            type: '',
            posted_at: null,
            primary: false,
            source: {
              name: '',
              type: ''
            },
            source_id: '',
            created_at: formatToLocalTime(new Date().toISOString())
          }
        ]
      };
    });
  };

  const onRemoveClick = (sourceId: string) => {
    setPropertySource((state) => {
      const filteredArray = state.propertySource.filter((property) => property.id !== sourceId);
      unregister(`source_type_${sourceId}`);
      unregister(`source_id_${sourceId}`);
      unregister(`source_link_${sourceId}`);
      return {
        ...state,
        propertySource: filteredArray
      };
    });
  };

  return (
    <>
      <RadioGroup name="sources_details">
        {propertySource.map((source, subIndex) => {
          const sourceConfig = filterFields[FormFields.source];
          const idConfig = filterFields[FormFields.source_id];
          const linkConfig = filterFields[FormFields.source_link];
          const SourceWidget = sourceConfig.Widget;
          const IdWidget = idConfig.Widget;
          const LinkWidget = linkConfig.Widget;

          return (
            <Box className={styles.boxCard} border={1} borderColor="grey.800" key={`name_${subIndex}`}>
              <Grid container spacing={2}>
                <Grid item xs={10} className={styles.row}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} className={styles.row}>
                      <SourceWidget
                        control={control}
                        label={sourceConfig.label}
                        size={'medium'}
                        name={`source_type_${source.id}`}
                        placeholder={sourceConfig.placeholder}
                        register={register}
                        onChange={() => {}}
                        items={getNormalisedOptions(propertySourceNames)}
                        rules={{
                          required: {value: true, message: "Поле обов'язкове"}
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} className={styles.row}>
                      <IdWidget
                        control={control}
                        label={idConfig.label}
                        size={'medium'}
                        name={`source_id_${source.id}`}
                        placeholder={idConfig.placeholder}
                        register={register}
                        onChange={() => {}}
                        rules={{
                          required: {value: true, message: "Поле обов'язкове"},
                          pattern: {
                            value: /^[0-9]+$/,
                            message: 'Тільки цифри'
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} className={styles.row}>
                      <LinkWidget
                        control={control}
                        label={linkConfig.label}
                        size={'medium'}
                        name={`source_link_${source.id}`}
                        placeholder={linkConfig.placeholder}
                        register={register}
                        onChange={() => {}}
                        rules={{
                          required: {value: true, message: "Поле обов'язкове"},
                          pattern: {
                            value: urlPattern,
                            message: 'Поле має бути дійсною URL-адресою.'
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} className={styles.flex}>
                  <Controller
                    control={control}
                    render={({field, fieldState, formState}) => {
                      return (
                        <FormControlLabel
                          {...field}
                          className={styles.radio}
                          checked={source.id ? source.id.toString() === field.value?.toString() : false}
                          value={source.id ? source.id.toString() : false}
                          control={<Radio />}
                          label="Основний"
                        />
                      );
                    }}
                    name={`source_primary`}
                  />
                </Grid>
              </Grid>
              {primarySourceId.toString() !== source.id.toString() && propertySource.length > 1 && (
                <IconButton
                  type={'button'}
                  className={styles.remove}
                  sx={{bgcolor: 'background.default'}}
                  onClick={() => {
                    onRemoveClick(source.id);
                  }}
                >
                  <CloseIcon width={20} height={20} />
                </IconButton>
              )}
            </Box>
          );
        })}
      </RadioGroup>
      <Button
        variant={'outlined'}
        type={'button'}
        className={styles.addContact}
        sx={{bgcolor: 'background.default'}}
        onClick={onAddContactClick}
      >
        <AddIcon />
        Додати ресурс
      </Button>
    </>
  );
};

export default SourcesInfo;
