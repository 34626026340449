import {useQuery} from 'react-query';
import {getDistrict, getStreetStreets} from '../api/filters';
import {API_ROUTES} from '../constants';

const useStreet = () => {
  const {data: streetNames = [], isLoading: isStreetNamesLoading} = useQuery(API_ROUTES.STREETS, getStreetStreets);

  return {streetNames, isStreetNamesLoading};
};

export default useStreet;
