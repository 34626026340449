import {Skeleton, TableCell, TableRow} from '@mui/material';
import React from 'react';
import {BaseRecord} from 'types';
import {ITableColumns} from '../types';

const BodyRows = <TData extends BaseRecord = any>(props: {
  data: TData;
  columns: ITableColumns<TData>[];
  isLoading: boolean;
  mutate: any;
}) => {
  const {data, columns, isLoading, mutate = () => {}} = props;

  return (
    <>
      {data?.length &&
        data.map((row: TData, index: number) => {
          return (
            <TableRow hover role="checkbox" tabIndex={-1} key={`row_${index}`}>
              {columns.map((column, indexCol) => {
                const align = columns[indexCol]?.align ? {align: columns[indexCol].align} : {};
                if (isLoading) {
                  return (
                    <TableCell key={indexCol} {...align}>
                      <Skeleton animation="wave" />
                    </TableCell>
                  );
                }

                const colContent = Array.isArray(column.key)
                  ? column.key.map((key) => {
                      return `${row[key]} `;
                    })
                  : row[column.key];

                const innerTd =
                  typeof column.render === 'function'
                    ? column.render(row, colContent, mutate)
                    : colContent?.toString() || '';
                return (
                  <TableCell
                    key={indexCol}
                    style={column.width ? {width: column.width} : {}}
                    sx={{borderColor: 'background.label'}}
                    {...align}
                  >
                    <>{innerTd}</>
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
    </>
  );
};

export default BodyRows;
