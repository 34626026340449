import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {RouterProvider} from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import {router} from './router';
import {ThemeProvider} from '../contexts/ThemeContext';
import {ErrorBoundary} from 'react-error-boundary';
import 'dayjs/locale/uk';
import {DistrictRCProvider} from '../contexts/DistrictRCContext';
import {LoadingProvider} from '../contexts/LoadingContext';
import {FilterContextProvider} from '../contexts/FiltersContext';
import {ToastContextProvider} from '../contexts/ToastContext';
import ErrorPage from './Error';
dayjs.locale('uk');
dayjs.extend(relativeTime);

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <ToastContextProvider>
            <DistrictRCProvider>
              <LoadingProvider>
                <FilterContextProvider>
                  <RouterProvider router={router} />
                </FilterContextProvider>
              </LoadingProvider>
            </DistrictRCProvider>
          </ToastContextProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
