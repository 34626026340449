import React, {FC, useState} from 'react';
import AdminsLayout from '../../Layouts/admins-layout';
import {Box} from '@mui/material';
import TableGreed from '../../common/table';
import {EstateCity, IListAdmin, ITableColumns} from '../../../types';
import {formatDateString} from '../../../helpers';
import AdminActions from './admin-actions';

const rows: ITableColumns<IListAdmin>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sortable: false
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sortable: false
  },
  {
    title: 'role',
    dataIndex: 'email',
    key: 'role',
    sortable: false,
    render: (item, value, mutate) => {
      return <>{value.name}</>;
    }
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'active',
    render: (item, value, mutate) => {
      return <>{value ? 'Активний' : 'Не активний'}</>;
    },
    sortable: false
  },
  {
    title: 'Імʼя',
    dataIndex: 'name',
    key: ['first_name', 'last_name'],
    render: (item, value, mutate) => {
      return <>{value}</>;
    },
    sortable: false
  },
  {
    title: 'Міста',
    dataIndex: 'name',
    key: 'cities',
    render: (item, value, mutate) => {
      return <>{value.map((item: EstateCity) => item.name)}</>;
    },
    sortable: false
  },
  {
    title: 'Дата створення',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (item, value, mutate) => {
      return <>{formatDateString(value, 'DD.MM.YYYY')}</>;
    },
    sortable: false
  },
  {
    title: 'Дії',
    dataIndex: 'id',
    key: 'id',
    render: (item, value, mutate) => {
      return <AdminActions admin={item} refetch={mutate} />;
    },
    sortable: false
  }
];

const AdminsList: FC = () => {
  const [take, setTake] = useState(10);
  return (
    <AdminsLayout title={'Адміни'}>
      <Box sx={{height: 400, width: '100%'}}>
        <TableGreed<any>
          columns={rows}
          requestUrl={''}
          defaultSorting={{name: 'created_at', direction: 'asc'}}
          take={take}
        />
      </Box>
    </AdminsLayout>
  );
};

export default AdminsList;
