import {Control, useWatch} from 'react-hook-form';
import {useEffect, useState} from 'react';
import {isEqual} from 'lodash';
interface FormData {
  [key: string]: any;
}
const useFormWatcher = (control: Control<FormData>, defaultValues: FormData | undefined) => {
  const watchedFields = useWatch({control});
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (!defaultValues) {
      return;
    }
    const hasChanged = Object.entries(watchedFields).some(([key, value]) => {
      return typeof value === 'object' ? !isEqual(value, defaultValues[key]) : value !== defaultValues[key];
    });
    setIsChanged(hasChanged);
  }, [watchedFields]);

  return isChanged;
};

export default useFormWatcher;
