import {ILabelListItem} from '../types';
import api from './server';
import {API_ROUTES} from '../constants';

export async function getLabels(): Promise<ILabelListItem[]> {
  try {
    const response = await api.get(API_ROUTES.LABELS);
    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export async function postLabels(
  id: string,
  data: {alias: string; comment: string}
): Promise<{success: boolean; message: string}> {
  try {
    const response = await api.post(`${API_ROUTES.PARSED_PROPERTIES}/${id}/labels`, data);
    return {success: true, message: response.data.message};
  } catch (error: any) {
    if (error.handleError) {
      return {
        success: false,
        message: error.errors.message
      };
    }
    return {
      success: false,
      message: error.response.data.message
    };
  }
}
