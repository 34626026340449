import styles from './styles.module.scss';
import {Box, Typography} from '@mui/material';
import React from 'react';
import {ReactComponent as NoImageIcon} from 'svg-icons/no-image.svg';

const NoImage = () => {
  return (
    <Box className={styles.noImage}>
      <NoImageIcon />
      <Typography variant={'caption'}>Фотографії обʼєкту відсутні.</Typography>
    </Box>
  );
};

export default NoImage;
