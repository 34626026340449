import styles from './styles.module.scss';
import {Box, Button, CircularProgress, Dialog, Paper, Typography} from '@mui/material';
import React, {useState} from 'react';
import {postLabels} from 'api/labels';
import {PropertyLabelsType} from 'types';
import {useToastContext} from 'contexts/ToastContext';
import {deleteProperty, deletePropertyFromArchive} from '../../../../api/base';
import {useLocation} from 'react-router-dom';
import {AD_BASE_URL} from '../../../../constants/routes';

const TrashConfirm: React.FC<{
  setOpen: (value: boolean) => void;
  open: boolean;
  propertyId: string;
  refetch: any;
}> = ({setOpen, open, propertyId, refetch}) => {
  const {setToastData, setOpenToast} = useToastContext();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const isBase = location.pathname.includes(AD_BASE_URL);

  const onSubmit = async () => {
    if (!propertyId) {
      return;
    }
    setLoading(true);
    const response = isBase
      ? await deleteProperty(propertyId)
      : await postLabels(propertyId, {alias: PropertyLabelsType.TRASH, comment: ''});
    setToastData({
      toastBody: response.message,
      toastTitle: response.success ? 'Успіх' : 'Помилка',
      toastType: response.success ? 'success' : 'error'
    });

    setOpenToast(true);
    setOpen(false);
    setLoading(false);
    refetch();
  };

  return (
    <>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        className={styles.dialogWrapper}
      >
        <Paper component={'form'}>
          <Typography variant={'h5'} sx={{mb: '24px', color: 'error.main'}}>
            <strong>{isBase ? "Увага, об'єкт буде видалено з бази" : "Увага об'єкт буде відправлено в смітник"}</strong>
          </Typography>
          <Box className={styles.btns}>
            <Button
              type={'button'}
              variant="text"
              onClick={() => {
                setOpen(false);
              }}
            >
              <strong>Скинути</strong>
            </Button>
            <Button
              className={styles.button}
              variant={'contained'}
              color={'primary'}
              type={'button'}
              onClick={onSubmit}
            >
              {loading ? <CircularProgress size={'24px'} /> : <strong>Видалити</strong>}
            </Button>
          </Box>
        </Paper>
      </Dialog>
    </>
  );
};

export default TrashConfirm;
