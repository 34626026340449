import {useQuery} from 'react-query';
import {RawItem} from '../types';
import {getAllowedRoles} from '../api/admins';

const useAllowedRoles = () => {
  const {data: allowedRoles = [], isLoading: isRolesLoading} = useQuery<
    RawItem[] | {success: boolean; message: string}
  >('admins/allowed-roles', getAllowedRoles);
  return {allowedRoles, isRolesLoading};
};

export default useAllowedRoles;
