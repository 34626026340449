import api from '../server';
import {ILoginResponse, ILoginValues} from '../../components/pages/sign-In';
import {API_ROUTES} from '../../constants';
import {IAdmin} from '../../types';

export async function register(data: any) {
  const response = await api.post(`/auth/register`, data);
  return response.data;
}

export async function login(data: ILoginValues): Promise<ILoginResponse> {
  const response = await api.post(API_ROUTES.LOGIN, data);
  return response.data.data;
}

export async function logout(): Promise<{message: string}> {
  const response = await api.post(API_ROUTES.LOGOUT);
  return response.data;
}

export async function getProfile(): Promise<IAdmin> {
  const response = await api.get(API_ROUTES.ADMINS_ME);
  return response.data.data;
}
