import {useQuery} from 'react-query';
import {getProfile} from 'api/auth';
import {useLocalStorage} from './useLocalStorage';
import {IAdmin} from 'types';

export const useUser = () => {
  const {value: token} = useLocalStorage('token');

  const {data: user, isLoading: isUserLoading} = useQuery<IAdmin>('', getProfile, {
    enabled: !!token,
    retry: false
  });

  return {user, isUserLoading};
};
