import {Box, IconButton} from '@mui/material';
import styles from './styles.module.scss';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import React, {useEffect} from 'react';
import classNames from 'classnames';
import {useSearchParams} from 'react-router-dom';

const Pagination: React.FC<{
  cursor:
    | {
        next: string | null;
        prev: string | null;
        hasMore: boolean;
      }
    | undefined;
}> = ({cursor}) => {
  const [searchParams, setSearchParams] = useSearchParams({
    cursor: ''
  });

  if (!cursor) {
    return null;
  }

  const {next, prev} = cursor;

  const onClick = (pageHash: string | null) => {
    if (!pageHash) {
      return;
    }
    setSearchParams({cursor: pageHash});
  };

  return (
    <Box className={styles.wrapper}>
      <IconButton
        onClick={() => {
          onClick(prev);
        }}
        sx={{bgcolor: 'background.secondary', color: 'background.info'}}
        className={classNames({[styles.disabled]: !prev})}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          onClick(next);
        }}
        sx={{bgcolor: 'background.secondary', color: 'background.info'}}
        className={classNames({[styles.disabled]: !next})}
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};
export default Pagination;
