import React, {useState} from 'react';
import {Button, CircularProgress, Tooltip} from '@mui/material';
import styles from './styles.module.scss';
import {ReactComponent as UserIcon} from 'svg-icons/user-round-x.svg';
import {ReactComponent as UserChechIcon} from 'svg-icons/userRoundCheck.svg';
import {ReactComponent as History} from 'svg-icons/history.svg';
import {ReactComponent as Edit} from 'svg-icons/edit.svg';
import {ReactComponent as Lock} from 'svg-icons/lock.svg';
import {IAdmin, IListAdmin} from 'types';
import {adminActiveTrigger, adminPassReset, getAdmin} from '../../../../api/admins';
import {useToastContext} from '../../../../contexts/ToastContext';
import {useQueryClient} from 'react-query';
import {API_ROUTES} from '../../../../constants';
import AdminForm from '../../../common/dialogs/admin-form';

const AdminActions: React.FC<{admin: IListAdmin; refetch: any}> = ({admin, refetch}) => {
  const {
    policies: {can_change_active_status, can_edit, can_reset_password}
  } = admin;

  const [triggerLoading, setTriggerLoading] = useState(false);
  const [changePassLoading, setChangePassLoading] = useState(false);
  const [createAdminOpen, setCreateAdminOpen] = useState(false);
  const [{adminLoading, adminInitialValues}, setAdmin] = useState<{
    adminLoading: boolean;
    adminInitialValues: IAdmin | null;
  }>({adminLoading: false, adminInitialValues: null});
  const {setToastData, setOpenToast} = useToastContext();
  const queryClient = useQueryClient();

  const setToast = async (data: {success: boolean; message: string}) => {
    setToastData({
      toastBody: data.message,
      toastTitle: data.success ? 'Успіх' : 'Помилка',
      toastType: data.success ? 'success' : 'error'
    });
    await queryClient.invalidateQueries([API_ROUTES.ADMINS]);
    setOpenToast(true);
  };

  const onActiveTrigger = async (trigger: boolean) => {
    setTriggerLoading(true);
    const response = await adminActiveTrigger({adminId: admin.id, active: trigger});
    await setToast(response);
    setTriggerLoading(false);
  };

  const onResetPass = async () => {
    setChangePassLoading(true);
    const response = await adminPassReset(admin.id);
    await setToast(response);
    setChangePassLoading(false);
  };

  const onEditClick = async () => {
    setAdmin((state) => ({
      ...state,
      adminInitialValues: null,
      adminLoading: true
    }));
    const response = await getAdmin(admin.id);
    //@ts-ignore
    if (response?.id) {
      //@ts-ignore
      setAdmin((state) => ({
        //@ts-ignore
        ...state,
        adminInitialValues: {
          //@ts-ignore
          cities: response?.cities.map((city) => city.code),
          //@ts-ignore
          role_id: response?.role?.id,
          //@ts-ignore
          email: response?.email,
          //@ts-ignore
          first_name: response?.first_name,
          //@ts-ignore
          last_name: response?.last_name,
          //@ts-ignore
          active: response?.active
        },
        adminLoading: false
      }));
      setCreateAdminOpen(true);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        {can_change_active_status &&
          (admin.active ? (
            <Tooltip title={'Деактивувати'}>
              <Button
                type={'button'}
                component={Button}
                variant={'contained'}
                color={'error'}
                className={styles.icon}
                onClick={async () => {
                  await onActiveTrigger(false);
                }}
                disabled={triggerLoading}
              >
                {triggerLoading ? <CircularProgress size={'24px'} /> : <UserIcon />}
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={'Активувати'}>
              <Button
                type={'button'}
                component={Button}
                variant={'contained'}
                color={'primary'}
                className={styles.icon}
                disabled={triggerLoading}
                onClick={async () => {
                  await onActiveTrigger(true);
                }}
              >
                {triggerLoading ? <CircularProgress size={'24px'} /> : <UserChechIcon />}
              </Button>
            </Tooltip>
          ))}
        {can_reset_password && (
          <Tooltip title={'Скинути пароль'}>
            <Button
              type={'button'}
              component={Button}
              variant={'contained'}
              color={'primary'}
              className={styles.icon}
              onClick={async () => {
                await onResetPass();
              }}
              disabled={changePassLoading}
            >
              {changePassLoading ? <CircularProgress size={'24px'} /> : <Lock />}
            </Button>
          </Tooltip>
        )}
        <Tooltip title={'Історія дій'}>
          <Button
            type={'button'}
            component={Button}
            variant={'contained'}
            color={'primary'}
            className={styles.icon}
            disabled={true}
          >
            <History />
          </Button>
        </Tooltip>
        {can_edit && (
          <Tooltip title={'Редагувати'}>
            <Button
              type={'button'}
              component={Button}
              variant={'contained'}
              color={'primary'}
              className={styles.icon}
              onClick={onEditClick}
              disabled={adminLoading}
            >
              {adminLoading ? <CircularProgress size={'24px'} /> : <Edit />}
            </Button>
          </Tooltip>
        )}
        {adminInitialValues && !adminLoading && (
          <AdminForm
            open={createAdminOpen}
            setOpen={() => {
              setCreateAdminOpen(false);
              setAdmin((state) => ({
                ...state,
                adminInitialValues: null,
                adminLoading: false
              }));
            }}
            initialValues={adminInitialValues}
            adminId={admin.id}
          />
        )}
      </div>
    </>
  );
};

export default AdminActions;
