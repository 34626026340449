import {Box, CircularProgress} from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

const Loader: React.FC<{
  height?: string;
  nativeStyles?: string;
  withBlur?: boolean;
  position?: Record<string, string>;
  size?: string;
}> = ({height = '50vh', nativeStyles, withBlur = false, position = {}, size = '10px'}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: height,
        left: '0',
        top: '0',
        bottom: '0',
        right: '0',
        ...position
      }}
      className={classNames({[styles.sceleton]: withBlur}, nativeStyles)}
    >
      <CircularProgress size={size} />
    </Box>
  );
};

export default Loader;
