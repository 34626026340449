import {useQuery} from 'react-query';
import {getBuildingTypes} from '../api/filters';

const useBuildingTypes = () => {
  const {data: buildingType = [], isLoading: isBuildingTypesLoading} = useQuery(
    'locations/building-type',
    getBuildingTypes,
    {
      retry: false
    }
  );

  return {buildingType, isBuildingTypesLoading};
};

export default useBuildingTypes;
