import {Box, Button, Collapse, Divider, Link, Typography} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {useState} from 'react';
import styles from './styles.module.scss';
import ParserFilters from './filters';
import AddIcon from '@mui/icons-material/Add';
import {AD_BASE_URL} from '../../../constants/routes';
import {useLocation} from 'react-router-dom';

const ParsersContentLayout = ({children, title, isLoading = false}) => {
  const location = useLocation();
  const isBase = location.pathname.includes(AD_BASE_URL);
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{width: '100%', mt: 0, mb: 'auto'}}>
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: '24px',
          position: 'relative'
        }}
      >
        <Typography variant="h4">{title}</Typography>
        <Box sx={{display: 'flex', gap: '24px', flexShrink: '0'}}>
          {isBase && (
            <Button
              variant="outlined"
              sx={{flexShrink: 0, height: '48px', display: 'flex', gap: '8px', fontWeight: 700}}
              component={Link}
              href={`${AD_BASE_URL}/create`}
            >
              <AddIcon />
              <span>Додати обʼєкт</span>
            </Button>
          )}
          <Button
            variant="contained"
            sx={{flexShrink: 0, height: '48px', display: 'flex', gap: '8px', fontWeight: 700}}
            onClick={() => setOpen(!open)}
          >
            <TuneIcon />
            <span>Фільтри</span>
            {open ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Box>
        <Divider className={styles.divider} />
      </Box>
      <Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ParserFilters isLoading={isLoading} />
        </Collapse>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default ParsersContentLayout;
