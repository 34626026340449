import {IconButton, Snackbar} from '@mui/material';
import {ReactComponent as CopyIcon} from 'svg-icons/copy.svg';
import {useState} from 'react';

const ClipboardCopy = ({value}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(value).then(() => {});
  };

  return (
    <>
      <IconButton onClick={handleClick} color="primary" sx={{padding: '4px'}}>
        <CopyIcon />
      </IconButton>
      <Snackbar
        message="Copied to clibboard"
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};

export default ClipboardCopy;
