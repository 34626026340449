import styles from './styles.module.scss';
import classNames from 'classnames';
import {Box} from '@mui/material';
import React from 'react';

const Label: React.FC<{title: string; variant: 'main' | 'default' | 'label'}> = ({title, variant = 'main'}) => {
  return (
    <Box className={classNames(styles.wrapper)} sx={{bgcolor: `background.${variant}`, color: 'text.primary'}}>
      {title}
    </Box>
  );
};
export default Label;
