import {useQuery} from 'react-query';
import {getCities} from '../api/filters';
import {EstateCity} from '../types';

const useCity = () => {
  const {data: cities = [], isLoading: isCitiesLoading} = useQuery<EstateCity[]>('locations/cities', getCities);
  return {cities, isCitiesLoading};
};

export default useCity;
