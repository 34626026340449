//@ts-nocheck
import api from '../server';
import {RawPrice} from '../../types';
import {API_ROUTES} from '../../constants';

export const getConvertedCurrency = async (options: {
  from_currency: string;
  to_currency: string;
  amount: string;
}): Promise<RawPrice> => {
  const response = await api.post(API_ROUTES.CURRENCY, options);
  return response.data.data;
  return {
    amount: '850',
    currency_code: 'USD'
  };
};
