import React from 'react';
import 'yet-another-react-lightbox/styles.css';
import Lightbox from 'yet-another-react-lightbox';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import 'yet-another-react-lightbox/plugins/counter.css';
import {ReactComponent as CloseIcon} from 'svg-icons/roundClose.svg';
import {ReactComponent as PrewIcon} from 'svg-icons/prewArrow.svg';
import {ReactComponent as NextIcon} from 'svg-icons/nextArrow.svg';
import styles from './styles.module.scss';

const LiteboxSlider = ({isOpen, setIsOpen, images, index}) => {
  return (
    <Lightbox
      index={index}
      className={styles.wrapper}
      open={isOpen}
      close={() => {
        setIsOpen((state) => ({
          open: false,
          stateIndex: 0
        }));
      }}
      slides={images?.map((image) => ({src: image.url}))}
      plugins={[Counter]}
      counter={{container: {style: {top: 'unset', bottom: 0}}}}
      render={{
        iconClose: () => <CloseIcon />,
        iconPrev: () => <PrewIcon />,
        iconNext: () => <NextIcon />
      }}
    />
  );
};

export default LiteboxSlider;
