import React, {useState} from 'react';
import {Box, CircularProgress, Link, Typography} from '@mui/material';
import styles from './styles.module.scss';
import {ReactComponent as CloudIcon} from 'svg-icons/cloud.svg';
import {postTemporaryMedia} from '../../../api/media';
import {useToastContext} from '../../../contexts/ToastContext';
import {IPropertyMedia} from '../../../types';
import {useFormWatcherContext} from '../../../contexts/FormWatcherProvider';

const FileUpload: React.FC<{
  onUploadSuccess: (data: IPropertyMedia[]) => void;
}> = ({onUploadSuccess}) => {
  const {setToastData, setOpenToast} = useToastContext();
  const {setFormContextChanged} = useFormWatcherContext();
  const [loading, setLoading] = useState(false);

  const onUploaded = async (files: File[]) => {
    setLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('media[]', file, file.name);
    });

    try {
      const data = await postTemporaryMedia(formData);
      onUploadSuccess(data);
      setFormContextChanged(true);
    } catch (error: any) {
      setOpenToast(true);
      if (error.handleError) {
        setToastData({
          toastBody: error.errors.message,
          toastTitle: 'Помилка',
          toastType: 'error'
        });

        setOpenToast(true);
      }
    }
    setLoading(false);
  };

  const splitFilesIntoBatches = (files: File[]) => {
    const batches: File[][] = [];
    while (files.length) {
      batches.push(files.splice(0, 5));
    }
    return batches;
  };

  const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files: File[] = Array.from(e.dataTransfer.files);

    if (files.length > 5) {
      const batches = splitFilesIntoBatches(files);
      batches.forEach((batch) => {
        onUploaded(batch);
      });
      return;
    }

    onUploaded(files);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = Array.from(e.target.files || []);

    if (files.length > 5) {
      const batches = splitFilesIntoBatches(files);
      batches.forEach((batch) => {
        onUploaded(batch);
      });
      return;
    }

    onUploaded(files);
  };

  return (
    <Box className={styles.wrapper} onDragOver={(e) => e.preventDefault()} onDrop={onFileDrop}>
      {loading ? (
        <CircularProgress size={'32px'} />
      ) : (
        <>
          <CloudIcon />
          <input type="file" onChange={handleFileChange} multiple={true} />
          <Typography variant="caption">
            Перетягніть картинку, або <Link component={'div'}>завантажте з компʼютера</Link>
          </Typography>
        </>
      )}
    </Box>
  );
};

export default FileUpload;
