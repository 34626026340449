import React, {useEffect, useState} from 'react';
import {Box, Button, Tooltip} from '@mui/material';
import styles from './styles.module.scss';
import {ReactComponent as House} from 'svg-icons/house.svg';
import {ReactComponent as AddBaseIcon} from 'svg-icons/base.svg';
import {ReactComponent as EditIcon} from 'svg-icons/edit.svg';
import {ReactComponent as MergeIcon} from 'svg-icons/merge.svg';
import {ReactComponent as TrashIcon} from 'svg-icons/trash.svg';
import {ReactComponent as BookIcon} from 'svg-icons/book.svg';
import {ReactComponent as ComentIcon} from 'svg-icons/message.svg';
import {AD_BASE_URL, PARSER_BASE_URL} from 'constants/routes';

import Link from '@mui/material/Link';
import MergePropertyDialog from '../../dialogs/merge';
import {ISelectItem, PropertyAssigner, PropertyStatus} from 'types';
import {createFromParsed} from '../../../../api/base';
import {useToastContext} from '../../../../contexts/ToastContext';
import Postponed from '../../dialogs/post-poned';
import TrashConfirm from '../../dialogs/trash-confirm';
import {useLocation, useParams} from 'react-router-dom';
import Actions from '../../actions';
import {propertyStatusNames} from '../../../../constants';

const ItemActions: React.FC<{
  actionList: any;
  propertyId: string;
  assignee: PropertyAssigner | null;
  refetch: any;
  parentList: 'base' | 'parser';
  propertyStatus?: PropertyStatus;
}> = ({actionList, propertyId, assignee, refetch, parentList, propertyStatus}) => {
  return (
    <Box className={styles.wrapper}>
      {parentList === 'base' && propertyStatus && (
        <Box
          className={styles.label}
          sx={{bgcolor: 'background.default', borderColor: 'background.info', color: 'background.info'}}
        >
          {propertyStatusNames[propertyStatus]}
        </Box>
      )}
      <Box
        className={styles.label}
        sx={{bgcolor: 'background.default', borderColor: 'background.info', color: 'background.info'}}
      >
        {assignee ? `${assignee.first_name} ${assignee.last_name}` : 'Не заасайнен'}
      </Box>

      <Box className={styles.row}>
        <Actions actionList={actionList} propertyId={propertyId} refetch={refetch} />
      </Box>
    </Box>
  );
};

export default ItemActions;
