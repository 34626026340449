import styles from './styles.module.scss';
import {Box, Button, Checkbox, CircularProgress, Dialog, Paper, Tooltip, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import classNames from 'classnames';
import {Controller, useForm} from 'react-hook-form';
import dayjs from 'dayjs';
import {DateTimePicker} from '@mui/x-date-pickers';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useQuery} from 'react-query';
import {getPublishTemplate, postPublication} from '../../../../api/base';
import {useToastContext} from '../../../../contexts/ToastContext';
import Gallery from './gallery';
import {formatDateString} from '../../../../helpers';

const Publish: React.FC<{
  setOpen: (value: boolean) => void;
  open: boolean;
  propertyId: string;
  refetch: any;
}> = ({setOpen, open, propertyId, refetch}) => {
  const {setToastData, setOpenToast} = useToastContext();
  const [loading, setLoading] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const {control, watch, reset, handleSubmit} = useForm({
    defaultValues: {
      scheduled_at: dayjs().add(15, 'minutes'),
      postponed: false
    }
  });

  const {data, isLoading} = useQuery(
    'publication/template',
    async () => {
      return await getPublishTemplate({id: propertyId});
    },
    {
      retry: false,
      enabled: !!propertyId
    }
  );

  const isPostponed = watch('postponed', false);

  const onEditorStateChange = (newEditorState: any) => {
    setEditorState(newEditorState);
  };

  const isContentEmpty = !editorState.getCurrentContent().hasText();

  const onSubmit = async (data: Record<string, any>) => {
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(/target="[^"]*"/g, '');

    const response = await postPublication(propertyId, {
      postponed: data.postponed,

      post_content: content,
      ...(data.scheduled_at &&
        data.postponed && {
          scheduled_at: formatDateString(data.scheduled_at, 'YYYY-MM-DD hh:mm:00')
        })
    });

    setToastData({
      toastBody: response?.message || '',
      toastTitle: response.success ? 'Успіх' : 'Помилка',
      toastType: response.success ? 'success' : 'error'
    });

    setOpenToast(true);
    if (!response?.success) {
      return;
    }
    setEditorState(EditorState.createEmpty());
    reset();
    refetch();
    setOpen(false);
  };

  useEffect(() => {
    if (data?.success || !data) {
      return;
    }
    setToastData({
      toastBody: data?.message || '',
      toastTitle: 'Помилка',
      toastType: 'error'
    });
    setOpenToast(true);
  }, [data, setOpenToast, setToastData]);

  useEffect(() => {
    if (!data?.template) {
      return;
    }

    const blocksFromHTML = htmlToDraft(data.template);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    setEditorState(EditorState.createWithContent(contentState));
  }, [data?.template]);

  return (
    <>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        className={styles.dialogWrapper}
      >
        <Paper component={'form'}>
          <Typography variant={'h5'} sx={{mb: '24px'}}>
            <strong>Публікація посту</strong>
          </Typography>
          <Box
            className={classNames(styles.comment, styles.editor)}
            sx={{borderColor: 'dark.secondary', bgcolor: 'background.secondary'}}
          >
            {isLoading ? (
              <CircularProgress size={'32px'} sx={{margin: 'auto'}} />
            ) : (
              <Editor
                editorState={editorState}
                wrapperClassName="postEditorContainer"
                editorClassName="postEditor"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  options: ['inline', 'link'],
                  inline: {
                    options: ['bold', 'italic', 'underline'],
                    bold: {icon: '/bold.svg', className: ''},
                    italic: {icon: '/italic.svg', className: ''},
                    underline: {icon: '/underline.svg', className: ''}
                  },
                  link: {
                    options: ['link'],
                    link: {icon: '/link.svg', className: ''}
                  }
                }}
              />
            )}
          </Box>
          {!isLoading && (
            <Box className={classNames(styles.comment)}>
              <Controller
                control={control}
                render={({field, fieldState, formState}) => {
                  return (
                    <label>
                      <Checkbox
                        sx={{width: '15px', height: '15px', marginRight: '12px'}}
                        {...field}
                        checked={field.value}
                      />
                      <Typography variant={'h6'}>Відкладена публікація</Typography>
                    </label>
                  );
                }}
                name={'postponed'}
              />
            </Box>
          )}

          <Box className={classNames(styles.comment, styles.timeWrapper)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {isPostponed && (
                <Controller
                  name="scheduled_at"
                  control={control}
                  render={({field}) => (
                    <DateTimePicker
                      {...field}
                      label=""
                      value={field.value}
                      onChange={(newValue) => field.onChange(newValue)}
                      ampm={false}
                      format="DD.MM.YY HH:mm"
                      timeSteps={{minutes: 15}}
                      minDate={dayjs()}
                      minTime={dayjs().add(5, 'minutes')}
                    />
                  )}
                />
              )}
            </LocalizationProvider>
          </Box>
          {data?.media && (
            <Box className={classNames(styles.comment, styles.timeWrapper)}>
              <Gallery media={data?.media} />
            </Box>
          )}

          <Box className={styles.btns}>
            <Button
              type={'button'}
              variant="text"
              onClick={() => {
                setEditorState(EditorState.createEmpty());
                reset();
                setOpen(false);
              }}
            >
              <strong>Скасувати</strong>
            </Button>
            <Tooltip title={isContentEmpty && !loading && 'Заповніть поле'}>
              <span>
                <Button
                  className={styles.button}
                  variant={'contained'}
                  color={'primary'}
                  type={'button'}
                  onClick={handleSubmit(onSubmit)}
                  disabled={isContentEmpty || loading}
                >
                  {loading ? <CircularProgress size={'24px'} /> : <strong>Опублікувати</strong>}
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Paper>
      </Dialog>
    </>
  );
};

export default Publish;
