import React from 'react';
import {TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';
import {BaseRecord} from 'types';
import {ITableColumns} from 'types';
import styles from './styles.module.scss';
import SortIcon from './sortIcon';

const HeadRows = <TRow extends BaseRecord = any>(props: {
  columns: ITableColumns<TRow>[];
  direction: 'asc' | 'desc';
  dataIndex: string;
  sortFn: any;
}) => {
  const {columns, direction, dataIndex, sortFn} = props;

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell: ITableColumns<TRow>, index: number) => {
          const align = headCell?.align ? {align: headCell.align} : {};

          const sortable = typeof headCell.sortable === 'undefined' ? true : headCell.sortable;

          return (
            <TableCell
              key={`headCell_${index}`}
              padding={'normal'}
              style={headCell.width ? {width: headCell.width} : {}}
              className={styles.MuiButtonBaseRoot}
              sortDirection={dataIndex === headCell.dataIndex ? direction : false}
              {...align}
              sx={{bgcolor: index === 0 ? 'dark.secondary' : 'background.paper', borderColor: 'background.label'}}
            >
              {!sortable ? (
                headCell.title
              ) : (
                <TableSortLabel
                  IconComponent={() => {
                    return <SortIcon direction={direction} active={headCell.dataIndex === dataIndex} />;
                  }}
                  active={headCell.dataIndex === dataIndex}
                  direction={direction}
                  onClick={() => {
                    sortFn((state: any) => {
                      return {
                        ...state,
                        sortOption: {
                          dataIndex: headCell.dataIndex,
                          direction: direction === 'asc' ? 'desc' : 'asc'
                        }
                      };
                    });
                  }}
                >
                  {headCell.title}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default HeadRows;
