import React, {createContext, Dispatch, SetStateAction, useContext, useState} from 'react';
import {IPropertyMedia} from '../types';

const FormWatcherContext = createContext<{
  isFormContextChanged: boolean;
  setFormContextChanged: Dispatch<SetStateAction<boolean>>;
  hasPendingUploads: boolean;
  setHasPendingUploads: Dispatch<SetStateAction<boolean>>;
  propertyImages: IPropertyMedia[];
  setPropertyImages: Dispatch<SetStateAction<IPropertyMedia[]>>;
}>({
  isFormContextChanged: false,
  setFormContextChanged: () => {},
  hasPendingUploads: false,
  setHasPendingUploads: () => {},
  propertyImages: [],
  setPropertyImages: () => {}
});

export const FormWatcherProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [isFormContextChanged, setFormContextChanged] = useState<boolean>(false);
  const [hasPendingUploads, setHasPendingUploads] = useState<boolean>(false);
  const [propertyImages, setPropertyImages] = useState<IPropertyMedia[]>([]);
  return (
    <FormWatcherContext.Provider
      value={{
        isFormContextChanged,
        setFormContextChanged,
        hasPendingUploads,
        setHasPendingUploads,
        propertyImages,
        setPropertyImages
      }}
    >
      {children}
    </FormWatcherContext.Provider>
  );
};

export const useFormWatcherContext = () => {
  const context = useContext(FormWatcherContext);
  if (!context) {
    throw new Error('useCustomerFiltersContext must be used within a CustomerFiltersProvider');
  }
  return context;
};
