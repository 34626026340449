import React from 'react';

import {Box, Container} from '@mui/material';
import Header from './Header';
import Sidebar from './Sidebar';
import styles from './styles.module.scss';
import {FormWatcherProvider} from '../../contexts/FormWatcherProvider';

const HomeLayout = ({children}) => {
  const hasToken = localStorage.getItem('token');
  return (
    <div className={styles.wrapper}>
      <Header />
      <Container maxWidth="xl" sx={{px: {xs: 1, sm: 2}}} style={{display: 'flex', height: '100%', flexGrow: 1}}>
        <Box sx={{display: 'flex', width: '100%'}}>
          {hasToken && <Sidebar />}

          <Box
            sx={{
              ...(!hasToken && {mx: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center'}),
              px: '24px',
              flexGrow: 1
            }}
          >
            <FormWatcherProvider>{children}</FormWatcherProvider>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default HomeLayout;
