import {CurrencyCode, FormFields} from '../../../../types';
import {debounce, getNormalisedOptions} from '../../../../helpers';
import {currencyCodeNames, currencySymbols} from '../../../../constants';
import FieldWidget from '../index';
import React, {useMemo, useState} from 'react';
import {InputAdornment, Typography} from '@mui/material';
import classNames from 'classnames';
import styles from '../../inputWithSelect/styles.module.scss';
import {getConvertedCurrency} from '../../../../api/currency';
import {useToastContext} from '../../../../contexts/ToastContext';

const PriceWidget: React.FC<{
  control: any;
  register: any;
  initialAdornmentPrice: string;
  initialAdornmentCode: CurrencyCode;
  watch: any;
  name?: FormFields;
  gridSize?: number;
}> = ({
  control,
  register,
  initialAdornmentPrice,
  initialAdornmentCode,
  watch,
  name = FormFields.price,
  gridSize = 6
}) => {
  const [{adornmentCode, adornmentPrice}, setPrice] = useState({
    adornmentCode: initialAdornmentCode,
    adornmentPrice: initialAdornmentPrice
  });
  const fromCurrency = watch('adornment_price', '');
  const {setToastData, setOpenToast} = useToastContext();

  const onPriceChange = debounce(async (name: string, value: string) => {
    if (!value) {
      return;
    }
    try {
      const response = await getConvertedCurrency({
        from_currency: fromCurrency,
        to_currency: fromCurrency === CurrencyCode.UAH ? CurrencyCode.USD : CurrencyCode.UAH,
        amount: value.replace(/[^0-9]/g, '')
      });
      setPrice({adornmentCode: response.currency_code, adornmentPrice: response.amount});
    } catch (error: any) {
      setToastData({
        toastBody: error?.errors?.message,
        toastTitle: 'Помилка',
        toastType: 'error'
      });
      setOpenToast(true);
    }
  }, 1000);

  const EndAdornment = useMemo(() => {
    return (
      <InputAdornment position="end" className={classNames(styles.wrapper)}>
        <Typography noWrap={true} variant={'body2'} sx={{maxWidth: '55px'}}>
          {
            //@ts-ignore
            currencySymbols[adornmentCode]
          }
          {adornmentPrice}
        </Typography>
      </InputAdornment>
    );
  }, [adornmentCode, adornmentPrice]);

  return (
    <FieldWidget
      control={control}
      register={register}
      filedName={name}
      items={getNormalisedOptions(currencyCodeNames)}
      EndAdornment={EndAdornment}
      onChange={onPriceChange}
      gridSize={gridSize}
      rules={{
        pattern: {
          value: /^[0-9]+$/,
          message: 'Тільки цифри'
        }
      }}
    />
  );
};

export default PriceWidget;
