import React, {createContext, Dispatch, SetStateAction, useContext, useState} from 'react';

interface FilterContextType {
  filters: Record<string, any>;
  setFilters: Dispatch<SetStateAction<Record<string, any>>>;
  filterErrors: Record<string, any>;
  setFilterErrors: Dispatch<SetStateAction<Record<string, any>>>;
}

const LoadingContext = createContext<FilterContextType>({
  filters: {},
  setFilters: () => {},
  filterErrors: {},
  setFilterErrors: () => {}
});

export const FilterContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [filters, setFilters] = useState({});
  const [filterErrors, setFilterErrors] = useState<Record<string, any>>({});

  return (
    <LoadingContext.Provider value={{filters, setFilters, filterErrors, setFilterErrors}}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useFilterContext = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};
