import React, {useState} from 'react';
import {Box, Button, CircularProgress, Dialog, IconButton, Paper, RadioGroup, Typography} from '@mui/material';
import {ReactComponent as CloseIcon} from 'svg-icons/close.svg';
import styles from './styles.module.scss';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import MergeListItem from './item';
import {FormFields} from 'types';
import {useQuery} from 'react-query';
import {mergeProperty} from 'api/properties';
import FieldWidget from 'components/common/widgets';
import {useToastContext} from 'contexts/ToastContext';
import {getBaseProperties, getBaseProperty} from '../../../../api/base';
import NoData from '../../no-data';
import {useLocation, useNavigate} from 'react-router-dom';

const MergePropertyDialog: React.FC<{
  onClose: () => void;
  open: boolean;
  parsedPropertyId: string;
  redirectAfterMerge?: boolean;
}> = ({onClose, open, parsedPropertyId, redirectAfterMerge = false}) => {
  const {control, handleSubmit, setValue, watch, register} = useForm();
  const inputValue = watch('property_id', '');
  const radioValue = watch('property_to_merge', '');
  const [loading, setLoading] = useState(false);
  const {setToastData, setOpenToast} = useToastContext();
  const navigate = useNavigate();

  const {
    data: resultData,
    isLoading,
    isError
  } = useQuery(['MergePropertyDialog', inputValue], () => getBaseProperties({property_id: inputValue}), {
    enabled: !!inputValue && inputValue.length > 0,
    retry: false
  });
  const location = useLocation();

  const replaceSlash = (input: string) => {
    const lastSlashIndex = input.lastIndexOf('/');
    if (lastSlashIndex === -1) {
      return input;
    }
    return input.substring(0, lastSlashIndex);
  };

  const onSubmit = async (data: any) => {
    if (!radioValue || !parsedPropertyId) {
      return;
    }

    setLoading(true);
    const response = await mergeProperty({parsedPropertyId, propertyId: radioValue});
    setToastData({
      toastBody: response.message,
      toastTitle: response.success ? 'Успіх' : 'Помилка',
      toastType: response.success ? 'success' : 'error'
    });
    setOpenToast(true);
    setLoading(false);

    if (!response.success) {
      return;
    }

    onClose();
    if (!redirectAfterMerge) {
      return;
    }
    const replaceUrl = replaceSlash(location.pathname);
    navigate(replaceUrl, {replace: true});
  };
  const restValues = async () => {
    setValue('property_to_merge', '');
  };

  const data = resultData?.data || [];

  return (
    <>
      <Dialog onClose={onClose} open={open} className={styles.wrapper}>
        <Paper component={'form'} className={styles.form}>
          <Box className={styles.top} sx={{bgcolor: 'background.secondary'}}>
            <Typography variant={'h5'}>
              <strong>Обʼєднати обʼєкти</strong>
            </Typography>
            <IconButton type={'button'} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={styles.inner}>
            <Box className={styles.input}>
              <FieldWidget
                control={control}
                register={register}
                filedName={FormFields.property_id}
                gridSize={12}
                rules={{
                  required: "Поле обов'язкове.",
                  minLength: {
                    value: 2,
                    message: 'Мінімум 2 символи.'
                  }
                }}
              />
            </Box>
            <Box className={classNames(styles.results, {[styles.center]: !![resultData]?.length})}>
              {data.length > 0 && !isError && resultData !== undefined ? (
                <RadioGroup name="unite_property_id">
                  {isLoading ? (
                    <Box className={styles.noData}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      {data.map((property, index) => (
                        <MergeListItem item={property} control={control} key={`item-${index}`} />
                      ))}
                    </>
                  )}
                </RadioGroup>
              ) : (
                <NoData isLoading={isLoading} />
              )}
            </Box>
          </Box>
          <Box className={styles.bottom} sx={{bgcolor: 'background.secondary'}}>
            <Button type={'button'} variant="text" onClick={restValues}>
              <strong>Скинути</strong>
            </Button>
            <Button
              variant={'contained'}
              color={'primary'}
              type={'submit'}
              onClick={handleSubmit(onSubmit)}
              disabled={!radioValue || loading}
              className={styles.btn}
            >
              {loading ? <CircularProgress size={'24px'} /> : <strong>Об'єднати</strong>}
            </Button>
          </Box>
        </Paper>
      </Dialog>
    </>
  );
};

export default MergePropertyDialog;
