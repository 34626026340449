import api from './server';
import {API_ROUTES} from '../constants';

export async function getComment(propertyId: string): Promise<{message: string} | {success: boolean; message: string}> {
  try {
    const response = await api.get(`${API_ROUTES.PROPERTIES}/${propertyId}/comment`);
    return {message: response.data.data.comment};
  } catch (error: any) {
    if (error.handleError) {
      return {
        success: false,
        message: error.errors.message
      };
    }
    return {
      success: false,
      message: error.response.data.message
    };
  }
}

export async function putComment(
  propertyId: string,
  data: {comment: string}
): Promise<{success: boolean; message: string}> {
  try {
    const response = await api.put(`${API_ROUTES.PROPERTIES}/${propertyId}/comment`, data);
    console.log('response', response);
    return {success: true, message: response.data.message};
  } catch (error: any) {
    if (error.handleError) {
      return {
        success: false,
        message: error.errors.message
      };
    }
    return {
      success: false,
      message: error.response.data.message
    };
  }
}
