import {Box, Button, Chip, CircularProgress, IconButton, Tooltip, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import styles from './styles.module.scss';
import ClearIcon from '@mui/icons-material/Clear';
import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import AddLabelDialog from '../../dialogs/add-label-dialog';
import {PropertyLabels, PropertyLabelsType, PublicationStatus} from '../../../../types';
import Loader from '../../loader';
import MergePropertyDialog from '../../dialogs/merge';
import {useFormWatcherContext} from '../../../../contexts/FormWatcherProvider';
import {ReactComponent as TrashIcon} from 'svg-icons/trash.svg';
import {ReactComponent as House} from 'svg-icons/house.svg';
import {ReactComponent as Base} from 'svg-icons/base.svg';
import {ReactComponent as Merge} from 'svg-icons/merge.svg';
import {ReactComponent as Save} from 'svg-icons/save.svg';
import {useLocation, useParams} from 'react-router-dom';
import {AD_BASE_URL} from '../../../../constants/routes';
import ItemActions from '../../listItem/item-actions';
import Actions from '../../actions';
import {useQueryClient} from 'react-query';

const PropertyLayout: React.FC<{
  children: React.ReactNode;
  title: string;
  labels: PropertyLabels[];
  loading?: boolean;
  formRef: any;
  setRenderLabels: Dispatch<SetStateAction<PropertyLabels[]>>;
  type: 'edit' | 'create';
  refetch: () => void;
  isFetching: boolean;
  publicationStatus: PublicationStatus;
}> = ({
  children,
  title,
  labels = [],
  loading = false,
  formRef,
  setRenderLabels,
  type,
  refetch,
  isFetching,
  publicationStatus
}) => {
  const {setFormContextChanged} = useFormWatcherContext();
  const [open, setOpen] = useState(false);
  const [mergeDialogOpen, setMergeDialogOpen] = useState(false);
  const {isFormContextChanged} = useFormWatcherContext();
  const location = useLocation();
  const isBase = location.pathname.includes(AD_BASE_URL);
  const {id: propertyId} = useParams();
  const queryClient = useQueryClient();
  const {filter: filterName = ''} = useParams();
  const handleDelete = (labelId: string) => {
    setRenderLabels((state) => {
      return state.filter((item) => item.id !== labelId);
    });
    setFormContextChanged(true);
  };

  const onAddLabelClick = () => {
    setOpen(true);
  };

  const onSaveSubmit = async (data: any) => {
    await formRef.current.submit();
  };

  useEffect(() => {
    setRenderLabels(labels);
  }, [labels.length]);

  const getParsedPropertyActions = () => {
    const hasTrashLabel = labels.filter((item) => item.label.alias === PropertyLabelsType.TRASH);
    const hasPostPonedLabel = labels.filter((item) => item.label.alias === PropertyLabelsType.POSTPONED);
    const rawArray = [
      !hasTrashLabel.length && 'trash',
      !hasPostPonedLabel.length && 'postponed',
      'putOff',
      type !== 'create' && 'merge'
    ];
    console.log('rawArray', rawArray);
    return rawArray.filter(Boolean);
  };

  const getPropertyActions = () => {
    switch (filterName) {
      case 'active':
        return ['trash', 'comment', 'archive'];
      case 'unpublished':
        return publicationStatus === PublicationStatus.UNPUBLISHED
          ? ['trash', 'published', 'doNotPublish', 'comment', 'archive']
          : ['trash', 'doNotPublish', 'comment', 'archive'];
      default:
        return ['trash', 'comment', 'revivedFromArchive'];
    }
  };

  const actionsList = isBase ? getPropertyActions() : getParsedPropertyActions();

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{width: '100%'}}>
            <Box
              sx={{
                bgcolor: 'background.label2'
              }}
              className={styles.wrapper}
            >
              <Typography variant="h4">{title}</Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: '12px',
                  flexShrink: '0',
                  flexWrap: 'wrap',
                  flexGrow: 1,
                  justifyContent: 'flex-end'
                }}
              >
                {propertyId && actionsList && actionsList?.length && (
                  <Actions
                    //@ts-ignore
                    actionList={actionsList}
                    propertyId={propertyId}
                    refetch={refetch}
                    formChanged={isFormContextChanged}
                    size={'medium'}
                    redirectAfterMerge={true}
                  />
                )}
                <Tooltip title={!isFormContextChanged ? 'Внесіть зміни' : ''} placement="top">
                  <span>
                    <Button
                      variant="contained"
                      onClick={onSaveSubmit}
                      className={styles.btn}
                      disabled={!isFormContextChanged || isFetching}
                    >
                      {isFetching ? <CircularProgress size={'24px'} /> : <Save />}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
              <Box
                className={styles.shadow}
                sx={{
                  bgcolor: 'background.label2'
                }}
              />
            </Box>

            <Box
              sx={{
                bgcolor: 'background.label2'
              }}
              className={styles.inner}
            >
              {type !== 'create' && !isBase && (
                <Box sx={{display: 'flex', gap: '8px', flexShrink: '0', flexWrap: 'wrap', width: '100%'}}>
                  {!!labels &&
                    labels.map((label, index) => (
                      <Chip
                        key={`chip_${index}`}
                        label={label.label.name}
                        onDelete={() => {
                          handleDelete(label.id);
                        }}
                        sx={{bgcolor: `background.default`, borderRadius: '4px'}}
                        variant="outlined"
                        deleteIcon={
                          <IconButton sx={{padding: 0}}>
                            <ClearIcon />
                          </IconButton>
                        }
                      />
                    ))}
                  <Button
                    variant={'contained'}
                    size={'small'}
                    sx={{textTransform: 'none', color: 'primary.main', bgcolor: 'background.label'}}
                    onClick={onAddLabelClick}
                  >
                    Додати лейбу
                    <AddIcon fontSize={'small'} />
                  </Button>
                </Box>
              )}

              <Box
                className={styles.shadow}
                sx={{
                  bgcolor: 'background.label2'
                }}
              />
            </Box>
            <Box>{children}</Box>
          </Box>
          <AddLabelDialog open={open} setOpen={setOpen} onAddLabel={setRenderLabels} propertyLabels={labels} />
        </>
      )}
    </>
  );
};

export default PropertyLayout;
