import React, {useState} from 'react';
import {ContactRole, RawEstateContacts} from 'types';
import styles from './styles.module.scss';
import {Box, Collapse, Link, Typography} from '@mui/material';
import ClipboardCopy from 'components/common/clipboardCopy';
import {propertyContactRole} from 'constants/index';

const ItemContacts: React.FC<{contacts: RawEstateContacts[]}> = ({contacts}) => {
  const [open, setOpen] = useState(false);
  return (
    <Box className={styles.row}>
      <Typography variant={'subtitle1'}>Контакти:</Typography>
      {contacts?.length &&
        contacts?.map(({name, role, phone_numbers}, i) => {
          return (
            <Typography variant={'subtitle2'} className={styles.adres} sx={{width: '100%'}} key={`item-${i}`}>
              <span>
                {name} {role === ContactRole.UNKNOWN ? '' : `(${propertyContactRole[role]})`}
              </span>
              {phone_numbers.length ? (
                <>
                  <span className={styles.row}>
                    {phone_numbers[0].phone_number} <ClipboardCopy value={phone_numbers[0].phone_number} />
                  </span>
                  {phone_numbers.length > 1 ? (
                    <>
                      <span>
                        <Link
                          component={'button'}
                          type={'button'}
                          onClick={() => {
                            setOpen(!open);
                          }}
                        >
                          Повʼязані телефони
                        </Link>
                        [{phone_numbers.length - 1}]
                      </span>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box className={styles.adres}>
                          {phone_numbers.map((number, index) => {
                            return index === 0 ? null : (
                              <span key={`sub-item-${index}`}>
                                {number.phone_number} <ClipboardCopy value={number.phone_number} />
                              </span>
                            );
                          })}
                        </Box>
                      </Collapse>
                    </>
                  ) : null}
                </>
              ) : null}
            </Typography>
          );
        })}
    </Box>
  );
};

export default ItemContacts;
