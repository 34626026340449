import {Box, Button, Link} from '@mui/material';
import {ReactComponent as MapIcon} from 'svg-icons/mapIcon.svg';

const OpenMap = ({address}) => {
  return (
    <Button
      variant={'outlined'}
      sx={{textTransform: 'none'}}
      href={address}
      size={'small'}
      component={Link}
      target={'_blank'}
    >
      <Box sx={{marginRight: '8px'}}>Див. на карті</Box> <MapIcon />
    </Button>
  );
};

export default OpenMap;
