import {Collapse, Divider, List, ListItemButton, ListItemText} from '@mui/material';
import {useLocation} from 'react-router-dom';

const SidebarSublist = ({listData, isOpen}) => {
  const location = useLocation();

  return (
    <>
      {!!listData.length ? (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {listData.map((item, index) => {
              return (
                <ListItemButton
                  selected={location.pathname.includes(item.routPath)}
                  sx={{pl: 4}}
                  href={item.routPath}
                  key={`item-${index}`}
                >
                  <ListItemText primary={item.name} />
                </ListItemButton>
              );
            })}
          </List>
          <Divider />
        </Collapse>
      ) : null}
    </>
  );
};

export default SidebarSublist;
