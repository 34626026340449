import {Box, Typography, useTheme} from '@mui/material';
import styles from './styles.module.scss';
import SelectDropdown from '../../selectDropdown';
import React from 'react';
import {PropertyAdmin} from '../../../../types';
import {propertyStatusNames, publicationStatusNames} from '../../../../constants';

const Status: React.FC<{
  control: any;
  register: any;
  isParser: boolean;
  assignee: PropertyAdmin | null;
  assigneeList: PropertyAdmin[];
  type: 'create' | 'edit';
}> = ({control, register, isParser, assignee, assigneeList, type}) => {
  const theme = useTheme();

  return (
    <Box className={styles.wrapper}>
      {isParser ? (
        <Box className={styles.row}>
          <Typography
            //@ts-ignore
            variant={'captionSmall'}
          >
            Асайн
          </Typography>
          <SelectDropdown
            label={''}
            name={'assignee'}
            onChange={() => {}}
            items={assigneeList.map((admin) => ({
              label: `${admin.first_name} ${admin.last_name}`,
              value: admin.id
            }))}
            control={control}
            register={register}
            placeholder={'Виберіть адміна'}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                //@ts-ignore
                borderColor: theme.palette.background.blue
              }
            }}
            size={''}
          />
        </Box>
      ) : (
        <Box className={styles.row}>
          <Typography
            //@ts-ignore
            variant={'captionSmall'}
          >
            Статус
          </Typography>
          <SelectDropdown
            label={''}
            disabled={type !== 'create'}
            name={'publication_status'}
            onChange={() => {}}
            items={Object.entries(publicationStatusNames).map(([key, value]) => ({
              value: key,
              label: value
            }))}
            control={control}
            register={register}
            placeholder={'placeholder'}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                //@ts-ignore
                borderColor: theme.palette.background.blue
              }
            }}
            size={''}
          />
        </Box>
      )}
    </Box>
  );
};

export default Status;
