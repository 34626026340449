import api from './server';

const mockResponse = [
  {title: 'The Shawshank Redemption', year: 1994},
  {title: 'The Godfather', year: 1972},
  {title: 'The Godfather: Part II', year: 1974},
  {title: 'The Dark Knight', year: 2008},
  {title: '12 Angry Men', year: 1957},
  {title: "Schindler's List", year: 1993},
  {title: 'Pulp Fiction', year: 1994}
];

export const getDistricts = async (params) => {
  const response = await api.get('/districts/', {params});
  return response.data;
};

export const getResidentialComplexes = async (params) => {
  const response = await api.get('/residential-complexes/', {params});
  return response.data;
};

export const createDistrict = async (body) => {
  const response = await api.post('/districts/', body);
  return response.data;
};

export const createRC = async (body) => {
  const response = await api.post('/residential-complexes/', body);
  return response.data;
};

export const getSearchList = async (params) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockResponse);
    }, 1000);
  });
};
