import {FormControl, FormHelperText, InputAdornment, MenuItem, OutlinedInput, Select, Typography} from '@mui/material';
import styles from './styles.module.scss';
import React, {useMemo} from 'react';
import classNames from 'classnames';
import {Controller} from 'react-hook-form';

const InputWithSelect = ({
  control,
  label,
  name,
  placeholder = '',
  size = '',
  items = [
    {
      label: '',
      value: ''
    }
  ],
  onChange = () => {},
  register = null,
  rules = {},
  EndAdornment = null
}) => {
  const StartAdornment = useMemo(() => {
    return (
      <InputAdornment position="start" className={classNames(styles.wrapper)}>
        <Controller
          control={control}
          render={({field, fieldState, formState}) => {
            return (
              <Select {...field} variant={'filled'}>
                {items?.map((item, index) => (
                  <MenuItem key={`item_${index}`} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            );
          }}
          name={`adornment_${name}`}
        />
      </InputAdornment>
    );
  }, [items]);

  return (
    <FormControl fullWidth control={control} name={name} className={classNames(styles[size])}>
      <Typography variant="caption" gutterBottom>
        {label}
      </Typography>
      <Controller
        control={control}
        rules={rules}
        render={({field, fieldState, formState}) => {
          const getErrorMessage = (fieldName, errors) => {
            return fieldName.split('.').reduce((acc, key) => acc?.[key] || null, errors);
          };

          const errorMessage = getErrorMessage(name, formState.errors);
          return (
            <>
              <OutlinedInput
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                  onChange(name, event.target.value);
                }}
                startAdornment={StartAdornment}
                placeholder={placeholder}
                endAdornment={EndAdornment}
                error={errorMessage}
              />
              {errorMessage && (
                <FormHelperText sx={{color: 'error.main'}} id="outlined-weight-helper-text">
                  <>{errorMessage['0'] || errorMessage}</>
                </FormHelperText>
              )}
            </>
          );
        }}
        name={name}
      />
    </FormControl>
  );
};

export default InputWithSelect;
