import * as React from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {FormControl, FormHelperText, Typography} from '@mui/material';
import dayjs from 'dayjs';
import styles from './styles.module.scss';
import {Controller} from 'react-hook-form';

const TimePicker = ({label, name, control, onDateChange = () => {}, defaultValues, register, rules = {}}) => {
  return (
    <FormControl fullWidth className={styles.wrapper}>
      <Typography variant="caption" gutterBottom>
        {label}
      </Typography>
      <Controller
        register={register}
        control={control}
        rules={rules}
        render={({field, fieldState, formState}) => {
          const hasError = Object.keys(formState.errors).length > 0;
          return (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker {...field} value={dayjs(new Date(field.value))} size={'small'} format="DD.MM.YYYY" />
              </LocalizationProvider>
              {hasError && (
                <FormHelperText sx={{color: 'error.main'}}>{formState?.errors[name]?.message}</FormHelperText>
              )}
            </>
          );
        }}
        name={name}
      />
    </FormControl>
  );
};

export default TimePicker;
