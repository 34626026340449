import React, {useState} from 'react';
import {Grid, Card, CardMedia, Button, Box} from '@mui/material';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import CancelIcon from '@mui/icons-material/Cancel';
import styles from './styles.module.scss';
import LiteboxSlider from '../liteboxSlider';
import FileUpload from '../file-loader';
import {mergeArrays} from '../../../helpers';
import {useFormWatcherContext} from '../../../contexts/FormWatcherProvider';
import NoImage from '../no-image';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DraggableImageGallery = ({images, setImages, showFileLoader = false}) => {
  const [{open, slideIndex}, setOpen] = useState({open: false, slideIndex: 0});
  const {setFormContextChanged} = useFormWatcherContext();

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = reorder(images, result.source.index, result.destination.index);
    setImages(items);
  };

  const handleUploadSuccess = (data) => {
    const newImages = mergeArrays(images, data);
    setImages(newImages);
  };

  const onCrossClick = (id) => {
    const newImages = images.filter((img) => img.uuid !== id);
    setImages(newImages);
    setFormContextChanged(true);
  };
  return (
    <>
      {showFileLoader && <FileUpload onUploadSuccess={handleUploadSuccess} />}
      {images?.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-gallery" direction="horizontal">
            {(provided) => (
              <Grid
                container
                spacing={2}
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={styles.wrapper}
              >
                {images?.map((image, index) => (
                  <Draggable key={image.uuid} draggableId={image.uuid} index={index}>
                    {(provided) => (
                      <Grid item ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Card sx={{position: 'relative'}}>
                          <Button
                            sx={{
                              position: 'absolute',
                              top: 5,
                              right: 5,
                              minWidth: 0,
                              minHeight: 0,
                              padding: 0,
                              zIndex: 2
                            }}
                            onClick={() => onCrossClick(image.uuid)}
                          >
                            <CancelIcon
                              sx={{
                                borderRadius: '50%',
                                width: 15,
                                height: 15,
                                backgroundColor: 'red',
                                color: '#fff'
                              }}
                            />
                          </Button>
                          <CardMedia
                            onClick={() =>
                              setOpen((state) => ({
                                open: true,
                                slideIndex: index
                              }))
                            }
                            component="img"
                            image={image.url}
                            alt={`Image ${index}`}
                            sx={{width: 150, height: 150}}
                          />
                        </Card>
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Box className={styles.noImage}>
          <NoImage />
        </Box>
      )}

      <LiteboxSlider images={images} isOpen={open} setIsOpen={setOpen} index={slideIndex} />
    </>
  );
};

export default DraggableImageGallery;
