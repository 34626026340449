import {EstateSource, EstateType} from 'types';
import {Button, Link, Tooltip, Typography} from '@mui/material';
import styles from './styles.module.scss';
import {OpenInNew as OpenInNewIcon} from '@mui/icons-material';
import {ReactComponent as BuildingIcon} from 'svg-icons/building.svg';
import React from 'react';

const PropertyHeader: React.FC<{type: EstateType; title: string; sources: EstateSource}> = ({type, title, sources}) => {
  const source = Array.isArray(sources) ? sources?.find((source) => source.primary) : sources;
  return (
    <>
      {type === EstateType.APARTMENT && (
        <Button sx={{padding: 0, minWidth: '20px', height: '20px'}}>
          <Tooltip title={'Квартира'} placement="top">
            <BuildingIcon />
          </Tooltip>
        </Button>
      )}
      <Typography variant="h6" sx={{display: 'flex', alignItems: 'center'}}>
        {title || 'Заголовок'}
      </Typography>
      {!!source?.link && (
        <Link target="_blank" className={styles.link} href={source.link}>
          <OpenInNewIcon width={20} height={20} />
        </Link>
      )}
    </>
  );
};

export default PropertyHeader;
