import {useParams} from 'react-router-dom';
import {useQuery, useQueryClient} from 'react-query';
import PropertyLayout from './property-layout';
import React, {useEffect, useRef, useState} from 'react';
import {getParsedProperty, putParsedProperties} from '../../../api/properties';
import Loader from '../loader';
import PropertyForm from './property-form';
import {useFormWatcherContext} from '../../../contexts/FormWatcherProvider';
import {useToastContext} from '../../../contexts/ToastContext';
import {getBaseProperty, postProperties, putProperties} from '../../../api/base';
import {formatDateString} from '../../../helpers';

const getRawObjects = (data, stringKey) =>
  Object.entries(data).flatMap(([key, value]) => {
    if (!key.startsWith(stringKey)) {
      return [];
    }
    return [{[key]: value}];
  });

const PropertyPage = ({title = '', type = 'edit', isParser = true}) => {
  const {id: propertyId} = useParams();
  const formRef = useRef(null);
  const queryClient = useQueryClient();
  const [renderLabels, setRenderLabels] = useState([]);

  const {setPropertyImages, propertyImages, setFormContextChanged, isFormContextChanged} = useFormWatcherContext();
  const {setToastData, setOpenToast} = useToastContext();

  const {
    data: resultData = [],
    isLoading = true,
    isSuccess,
    refetch,
    isFetching
  } = useQuery(
    [`property/${propertyId}`],
    () => (isParser ? getParsedProperty({id: propertyId}) : getBaseProperty({id: propertyId})),
    {
      enabled: type === 'edit',
      refetchOnMount: true,
      retry: false,
      keepPreviousData: false,
      cacheTime: 0
    }
  );

  const ignoredKeys = [
    'price',
    'address.city_code',
    'address.district',
    'address.residential_complex',
    'address.adornment_street_name',
    'address.building_type',
    'address.underground',
    'address.river',
    'address.street_type',
    'address.street_name',
    'address.newly_built',
    'address.building',
    'address.section',
    'address.entrance',
    'address.apartment',
    'address.geo_link',
    'address.is_center',
    'assignee',
    'adornment_price',
    'last_time_contacted_at',
    'adornment_address.street_name',
    'adornment_address',
    'callback_at'
  ];

  const ignoredErrorKeys = ['address'];

  const handleFormSubmit = async (data) => {
    const formData = new FormData();

    Object.entries(data).map(([key, value]) => {
      formData[key] = value;
    });

    const rawSources = getRawObjects(data, 'source_');

    const rawContacts = getRawObjects(data, 'contact_');

    const transformedSources = Object.values(
      rawSources.reduce((acc, item) => {
        const [key, value] = Object.entries(item)[0];
        const match = key.match(/(.+?)_(\d+)$/);
        if (match) {
          const [, field, id] = match;
          if (!acc[id]) {
            acc[id] = {id: parseInt(id), type: '', source_id: '', link: '', primary: false};
          }
          if (field === 'source_type') acc[id].type = value;
          if (field === 'source_id') acc[id].source_id = value;
          if (field === 'source_link') acc[id].link = value;
        }
        return acc;
      }, {})
    );

    const transformedContact = rawContacts.reduce((result, item) => {
      const [key, value] = Object.entries(item)[0];

      const match = key.match(/_(\d+)$/);
      if (match) {
        const id = match[1];
        let record = result.find((r) => r.id === id);

        if (!record) {
          record = {id: parseInt(id), name: '', role: '', comment: '', phone_numbers: []};
          result.push(record);
        }

        if (key.startsWith('contact_name')) {
          record.name = value;
        } else if (key.startsWith('contact_role')) {
          record.role = value;
        } else if (key.startsWith('contact_comment')) {
          record.comment = value;
        } else if (key.startsWith('contact_phone_number')) {
          const phoneMatch = key.match(/contact_phone_number_(\d+)_/);
          if (phoneMatch) {
            const phoneId = phoneMatch[1];
            record.phone_numbers.push({
              id: parseInt(phoneId),
              phone_number: value?.replace(/\D/g, '')
            });
          }
        } else if (key.startsWith('contact_new_phone_numbers_')) {
          const phoneMatch = key.match(/contact_new_phone_numbers_(\d+)_/);
          if (phoneMatch) {
            record.phone_numbers.push({
              id: null,
              phone_number: value?.replace(/\D/g, '')
            });
          }
        }
      }
      return result;
    }, []);

    const filterByUniqueNumber = (array) => {
      const seenIds = new Set();
      return array.filter((item) => {
        if (!seenIds.has(item.phone_number)) {
          seenIds.add(item.phone_number);
          return true;
        }
        return false;
      });
    };

    const consolidatedContact = transformedContact.reduce((result, item) => {
      let existingEntry = result.find((entry) => entry.id === item.id);

      if (existingEntry) {
        if (item.name) existingEntry.name = item.name;
        if (item.role) existingEntry.role = item.role;
        if (item.comment) existingEntry.comment = item.comment;

        existingEntry.phone_numbers = filterByUniqueNumber([...existingEntry.phone_numbers, ...item.phone_numbers]);
      } else {
        result.push(item);
      }

      return result;
    }, []);

    const normalizedData = Object.keys(formData).reduce(
      (acc, key) => {
        if (
          acc[key] ||
          ignoredKeys.includes(key) ||
          !Boolean(key) ||
          key.startsWith('source_') ||
          key.startsWith('contact_')
        ) {
          return acc;
        }

        acc[key] = data[key];
        return acc;
      },
      {
        assignee: {
          from_id: resultData?.assignee?.id,
          to_id: data.assignee
        },
        price: {amount: data.price, currency_code: data.adornment_price},
        sources: transformedSources.map((source) => {
          return {
            ...source,
            primary: +source.id === +data.source_primary
          };
        }),
        address: {
          ...(typeof data.address?.city_code === 'string' && {
            city_code: data.address?.city_code
          }),
          ...(data.address?.city_code?.value && {city_code: data.address.city_code.value}),
          ...(data?.address?.district?.value && {district_id: data?.address?.district.value}),
          ...(data?.address?.district?.label &&
            !data?.address?.district?.value && {
              district_name: data?.address?.district?.label
            }),
          ...(typeof data?.address?.district === 'string' && {
            district_name: data?.address?.district
          }),
          ...(data.address?.residential_complex?.value &&
            +data.address?.newly_built === 1 && {
              residential_complex_id: data.address?.residential_complex.value
            }),
          ...((data.address?.residential_complex?.label && +data.address?.newly_built === 1) ||
            (typeof data.address?.residential_complex === 'string' &&
              +data.address?.newly_built === 1 && {
                residential_complex_name:
                  typeof data.address?.residential_complex === 'string'
                    ? data.address?.residential_complex
                    : data.address?.residential_complex?.label
              })),
          ...(data.adornment_address.street_name && {
            street_type: data.adornment_address.street_name
          }),
          ...(data.address?.street_name && {
            street_name: data.address?.street_name
          }),
          ...(data.address?.building_type?.value &&
            +data.address?.newly_built === 0 && {
              building_type_id: data.address?.building_type.value
            }),
          ...(typeof data?.address.building_type === 'string' &&
            +data.address?.newly_built === 0 && {building_type_name: data?.address?.building_type}),
          newly_built: +data.address?.newly_built === 1,
          building: data.address?.building ? data.address?.building : '',
          section: data.address?.section ? data.address?.section : '',
          entrance: data.address?.entrance ? data.address?.entrance : '',
          apartment: data.address?.apartment ? data.address?.apartment : '',
          geo_link: data.address?.geo_link ? data.address?.geo_link : '',
          is_center: data.address?.is_center
        },
        contacts: consolidatedContact,
        labels: renderLabels?.map((item) => ({
          alias: item.label.alias,
          comment: item.comment
        })),
        media: propertyImages,
        ...(data?.last_time_contacted_at && {
          last_time_contacted_at: formatDateString(data.last_time_contacted_at)
        }),
        ...(data?.callback_at &&
          !isNaN(new Date(data.callback_at).getTime()) && {
            callback_at: formatDateString(data.callback_at)
          })
      }
    );

    try {
      const response = isParser
        ? await putParsedProperties(propertyId, normalizedData)
        : type === 'edit'
        ? await putProperties(propertyId, normalizedData)
        : await postProperties(normalizedData);
      setToastData({
        toastBody: response.message,
        toastTitle: 'Успіх',
        toastType: 'success'
      });
      setOpenToast(true);
      if (type === 'create') {
        return;
      }

      if (response.success) {
        isParser
          ? await queryClient.invalidateQueries([`parsed-properties/${propertyId}`])
          : await queryClient.invalidateQueries([`property/${propertyId}`]);
        await refetch();
      }
    } catch (error) {
      if (error?.errors?.message) {
        setToastData({
          toastBody: error.errors.message,
          toastTitle: 'Помилка',
          toastType: 'error'
        });
        setOpenToast(true);
      }
      if (error?.errors?.errors) {
        const normalizedErrors = Object.keys(error.errors.errors).reduce(
          (acc, key) => {
            if (acc[key] || ignoredErrorKeys.includes(key)) {
              return acc;
            }

            acc[key] = error.errors.errors[key];
            return acc;
          },
          {
            districts_autocomplete: error.errors.errors.address
          }
        );
        Object.entries(normalizedErrors).forEach(([key, value]) => {
          if (key.startsWith('address.')) {
            if (key.endsWith('_name') || key.endsWith('_id')) {
              const trimmedKey = key.replace(/(_name|_id)$/, '');
              formRef.current.setFormErrors(trimmedKey, value);
              return;
            }
            formRef.current.setFormErrors(key.toString(), value);
          }
          const existFieldKey = Object.entries(formRef.current.defaultValues).find(
            ([defaultKey, defaultValue]) => defaultKey === key
          );
          if (!existFieldKey?.length) {
            console.log('error', key, value);
            return;
          }
          formRef.current.setFormErrors(key, value);
        });
      }
    }
  };

  useEffect(() => {
    setRenderLabels(resultData.labels);
  }, [resultData.labels]);

  useEffect(() => {
    setPropertyImages(resultData.media);
  }, [resultData.media, setPropertyImages]);

  return (
    <PropertyLayout
      title={title}
      labels={renderLabels}
      setRenderLabels={setRenderLabels}
      loading={isLoading}
      onEditSubmit={handleFormSubmit}
      formRef={formRef}
      type={type}
      refetch={refetch}
      isFetching={isFetching}
      publicationStatus={resultData?.publication_status}
    >
      {isFetching ? (
        <Loader />
      ) : (
        <PropertyForm
          data={type === 'edit' ? resultData : null}
          isParser={isParser}
          ref={formRef}
          onSubmit={handleFormSubmit}
        />
      )}
    </PropertyLayout>
  );
};

export default PropertyPage;
