import {Box, Grid, Link, Tooltip, Typography} from '@mui/material';
import React from 'react';
import Label from 'components/common/label';
import styles from './styles.module.scss';
import {ContactRole, EstateSource, OfferType, PropertyLabels, PropertyLabelsType, RawEstateContacts} from 'types';
import {labelNames, offerTypeNames, propertySourceNames} from 'constants/index';
import {convertTimeAgo} from 'helpers';
import ListItemLabels from '../../list-item-labels';

const PropertyItemFooter: React.FC<{
  propertyId: string;
  sources: EstateSource;
  latestPublicationAt: string;
  lastTimeContactedAt: string | null;
  createdAt: string;
  contacts: RawEstateContacts[];
  showLabels?: boolean;
  labels: PropertyLabels[];
  offerType: string;
  isMergeItem?: boolean;
}> = ({
  sources,
  latestPublicationAt,
  lastTimeContactedAt,
  createdAt,
  contacts,
  propertyId,
  showLabels = true,
  labels,
  offerType,
  isMergeItem = false
}) => {
  const source = Array.isArray(sources) ? sources.find((source) => source.primary) : sources;

  return (
    <Grid container spacing={4} className={styles.wrapper}>
      {showLabels && (
        <Grid item className={styles.col}>
          <ListItemLabels labels={labels} offerType={offerType} />
        </Grid>
      )}
      <Grid item className={styles.col}>
        <Box className={styles.item}>
          <Typography variant={'caption'} sx={{color: 'text.primary'}}>
            ID об'кту:
          </Typography>
          <Typography variant={'caption'}>{propertyId}</Typography>
        </Box>
        {source && source.id && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              {
                //@ts-ignore
                propertySourceNames[source.type]
              }{' '}
              ID:
            </Typography>
            <Typography variant={'caption'}>{source.id}</Typography>
          </Box>
        )}
        {latestPublicationAt && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              Опубліковано:
            </Typography>
            <Typography variant={'caption'}>
              <Link href={'#'}>{convertTimeAgo(latestPublicationAt)}</Link>
            </Typography>
          </Box>
        )}
        {!!lastTimeContactedAt && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              Дата контакту:
            </Typography>
            <Typography variant={'caption'}>
              {Array.isArray(lastTimeContactedAt) ? (
                lastTimeContactedAt
              ) : (
                <Link component={'div'}>{convertTimeAgo(lastTimeContactedAt)}</Link>
              )}
            </Typography>
          </Box>
        )}
        {!isMergeItem && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              Парсінг:
            </Typography>
            <Link component={'div'} variant={'caption'} sx={{color: 'text.success'}}>
              {convertTimeAgo(createdAt)}
            </Link>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default PropertyItemFooter;
