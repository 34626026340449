import {Box, Button, Link, List, ListItemButton, ListItemText} from '@mui/material';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {useState} from 'react';
import SidebarSublist from '../sidebarSublist';

const SidebarListItem = ({data}) => {
  const [open, setOpen] = useState({parser: false, adBase: false});
  const handleClick = (key) => {
    setOpen((state) => {
      return {
        ...state,
        [key]: !state[key]
      };
    });
  };
  return (
    <>
      {data.map((item, index) => {
        const {Icon, name, routPath, children, key} = item;
        const hasChildren = !!children.length;
        return (
          <List component="div" key={`item_${index}`}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <ListItemButton
                component={hasChildren ? Button : Link}
                key={name}
                href={hasChildren ? null : routPath}
                onClick={
                  hasChildren
                    ? () => {
                        handleClick(key);
                      }
                    : () => {}
                }
                sx={{textTransform: 'none', borderRadius: '4px'}}
              >
                <Icon stroke={'currentColor'} />
                <ListItemText primary={name} sx={{ml: 1}} />
                {hasChildren ? <>{open[key] ? <ExpandLess /> : <ExpandMore />}</> : null}
              </ListItemButton>
            </Box>
            <SidebarSublist isOpen={open[key]} listData={children} />
          </List>
        );
      })}
    </>
  );
};

export default SidebarListItem;
