import {BaseRecord} from 'types';
import {useEffect, useMemo, useState} from 'react';
import {Box, Table, TableBody, TableContainer} from '@mui/material';
import HeadRows from './head-rows';
import BodyRows from './body-rows';
import styles from './styles.module.scss';
import {useQuery} from 'react-query';
import {getAdmins} from '../../../api/filters';
import {useSearchParams} from 'react-router-dom';
import Pagination from '../pagination';
import {API_ROUTES} from '../../../constants';

const mockData = [
  {
    id: '1',
    name: 'Name',
    event_type: 'Anniversary work',
    status: 'Completed',
    event_date: '2024-10-04T14:20:10+00:00',
    created_at: '2024-10-04T14:20:10+00:00',
    access: 'full',
    email: 'example@google.com',
    completed_date: '2024-10-04T14:20:10+00:00',
    photo: '/images/style-select/ordinary-style.png'
  },
  {
    id: '2',
    name: 'Name2',
    event_type: 'Anniversary work',
    status: 'Skipped',
    event_date: '2024-10-04T14:20:10+00:00',
    created_at: '2024-10-04T14:20:10+00:00',
    access: 'full',
    email: 'example@google.com',
    completed_date: '2024-10-04T14:20:10+00:00',
    photo: '/images/style-select/ordinary-style.png'
  },
  {
    id: '3',
    name: 'Name3',
    event_type: 'Birthday',
    status: 'Completed',
    event_date: '2024-10-04T14:20:10+00:00',
    created_at: '2024-10-04T14:20:10+00:00',
    access: 'full',
    email: 'example@google.com',
    completed_date: '2024-10-04T14:20:10+00:00',
    photo: '/images/style-select/ordinary-style.png'
  }
];

interface TableGreedProps {
  columns: any[];
  requestUrl: string;
  take?: number;
  queryOptions?: Record<string, any>;
  defaultSorting?: {name: string; direction: 'asc' | 'desc'};
}

const TableGreed = <TResponse extends BaseRecord = any>(props: TableGreedProps) => {
  const {columns, requestUrl, take = 10, queryOptions = {}, defaultSorting} = props;

  let [searchParams, setSearchParams] = useSearchParams();
  const cursor = searchParams.get('cursor') || '';

  const {
    data: adminsData = {data: [], cursor: ''},
    isLoading,
    refetch
  } = useQuery([API_ROUTES.ADMINS, cursor], async () => {
    return await getAdmins({cursor});
  });

  const initialState = {
    page: 0,
    listData: mockData,
    hasMore: true,
    rowsPerPage: take,
    sortOption: {
      dataIndex: defaultSorting?.name || '',
      direction: defaultSorting?.direction || 1
    }
  };

  const [
    {
      page,
      listData,
      sortOption: {dataIndex, direction}
    },
    setState
  ] = useState<any>(initialState);

  const mutate = () => {};
  useEffect(() => {
    return () => {
      if (!searchParams.has('cursor')) {
        return;
      }
      searchParams.delete('cursor');
      setSearchParams(searchParams);
    };
  }, [searchParams]);

  const renderData: any = useMemo(() => {
    const hasDataLength = !!adminsData?.data || [];
    const skeletonArray = Array.from({length: hasDataLength ? listData.length : 3}, (_, i) => i + 1);
    return isLoading ? skeletonArray : adminsData?.data;
  }, [isLoading, adminsData.data]);

  return (
    <div className={styles.container}>
      <Box className={styles.wrapper} sx={{borderColor: 'background.label'}}>
        <TableContainer>
          <Table sx={{minWidth: 750, tableLayout: 'auto'}} aria-label="tableTitle">
            <HeadRows columns={columns} direction={direction} dataIndex={dataIndex} sortFn={setState} />
            <TableBody>
              {!listData.length && !isLoading && (
                <tr>
                  <td colSpan={columns.length}>noData</td>
                </tr>
              )}
              <BodyRows<TResponse> data={renderData} columns={columns} isLoading={isLoading} mutate={refetch} />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Pagination
        //@ts-ignore
        cursor={adminsData?.cursor}
      />
    </div>
  );
};

export default TableGreed;
