import {useQuery} from 'react-query';
import {getSearchAdmins} from '../api/filters';
import {PropertyAdmin} from '../types';
const useAssignee = (only_active = true, name = ''): {assignee: PropertyAdmin[]; isAdminsLoading: boolean} => {
  const {data: assignee = [], isLoading: isAdminsLoading} = useQuery(
    ['admins', only_active, name],
    () => getSearchAdmins({only_active, name}),
    {
      retry: false
    }
  );
  return {assignee, isAdminsLoading};
};

export default useAssignee;
