import {Box, Button, CircularProgress, Divider, Grid, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {debounce, formatDateString, getNormalisedOptions} from 'helpers';
import useCity from 'hooks/useCity';
import useDistricts from 'hooks/useDistrict';
import useResidentialComplex from 'hooks/useResidentalComplex';
import {
  childrenAllowedStatus,
  currencySymbols,
  eastTypeNames,
  offerTypeNames,
  petsAllowedStatus,
  propertyContactRole,
  propertyFiltersRoom
} from 'constants/index';
import useBuildingTypes from 'hooks/useBuildingType';
import styles from './styles.module.scss';
import classNames from 'classnames';
import FieldWidget from 'components/common/widgets';
import {FormFields} from 'types';
import SourceSelect from 'components/common/widgets/sourceSelect';
import {getConvertedCurrency} from 'api/currency';
import {useFilterContext} from 'contexts/FiltersContext';
import useAssignee from 'hooks/useAssignee';
import {useLocation} from 'react-router-dom';
import {AD_BASE_URL, PARSER_BASE_URL} from 'constants/routes';

const ParserFilters = ({isLoading = false}) => {
  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    setError,
    reset,
    formState: {errors: formStateErrors}
  } = useForm({
    mode: 'onSubmit'
  });

  const {cities} = useCity();
  const {districts} = useDistricts();
  const {residentialComplex} = useResidentialComplex();
  const {buildingType} = useBuildingTypes();
  const {filterErrors, setFilters} = useFilterContext();
  const {assignee: assignerList} = useAssignee(false);
  const location = useLocation();
  const isOwnerFilters = location.pathname.includes('all');
  const isParser = location.pathname.includes(PARSER_BASE_URL);
  const isBase = location.pathname.includes(AD_BASE_URL);

  const displayBuildingTypesSelect = watch('newly_built');

  const ignoredKeys = [
    'assignee_id',
    'last_time_contacted_at_from',
    'last_time_contacted_at_to',
    'adornment_price_from',
    'adornment_price_to',
    'source',
    'price_from',
    'price_to',
    'river',
    'underground',
    'smallRegion',
    'districts',
    'street_name_autocomplete',
    'newly_built',
    'building_types',
    'residential_complexes',
    'rooms',
    'living_area_from',
    'living_area_to',
    'floor_from',
    'floor_to',
    'children',
    'pets',
    'name',
    'role',
    'source_id',
    'contact',
    'name',
    'role',
    'phone_numbers',
    'author_id'
  ];

  const onApplyClick = async (data) => {
    try {
      const formData = new FormData();

      Object.entries(data).map(([key, value]) => {
        formData[key] = value;
      });
      console.log(formData);

      const normalizedData = Object.keys(formData).reduce(
        (acc, key) => {
          if (acc[key] || ignoredKeys.includes(key) || !Boolean(key)) {
            return acc;
          }
          acc[key] = data[key];
          return acc;
        },
        {
          ...(data.last_time_contacted_at_from && data.last_time_contacted_at_to
            ? {
                last_time_contacted_at: {
                  from: formatDateString(data.last_time_contacted_at_from),
                  to: formatDateString(data.last_time_contacted_at_to)
                }
              }
            : {}),
          source: {
            type: data.source ?? '',
            id: data.source_id ?? ''
          },
          price: {
            from: data.price_from ?? '',
            to: data.price_to ?? ''
          },
          ...(!!data.districts && {
            districts: data.districts?.sort((a, b) => a - b)
          }),
          ...(data?.newly_built ? {newly_built: Boolean(data.newly_built)} : {}),
          street_name: data.street_name_autocomplete?.label ?? '',
          residential_complexes: displayBuildingTypesSelect ? data.residential_complexes : data.building_types,
          features: {
            ...(data.rooms && {rooms: data.rooms.sort((a, b) => a - b)}),
            living_area: {
              from: data?.living_area_from,
              to: data?.living_area_to
            },
            floor: {
              from: data?.floor_from,
              to: data?.floor_to
            },
            children: data?.children?.filter(Boolean),
            pets: data?.pets?.filter(Boolean),
            contact: {
              name: data?.name,
              roles: data.roles?.filter(Boolean),
              phone_number: data.phone_numbers
            }
          },
          ...(data.assignee_id && {assignee_id: data.assignee_id}),
          ...(data.author_id && {assignee_id: data.author_id})
        }
      );
      setFilters(normalizedData);
    } catch (error) {
      console.log('error', error);
    }
  };
  const onPriceInputChange = debounce(async (name, value) => {
    if (!value) {
      setValue(`adornment_${name}`, '');
      return;
    }
    const response = await getConvertedCurrency({
      from_currency: 'UAH',
      to_currency: 'USD',
      amount: value
    });
    setValue(`adornment_${name}`, `${currencySymbols[response.currency_code]}${response.amount}`);
  }, 500);

  useEffect(() => {
    if (!filterErrors) {
      return;
    }
    Object.entries(filterErrors).forEach(([key, value]) => {
      setError(key.toString(), {type: 'manual', message: Array.isArray(value) ? value[0] : value});
    });
  }, [filterErrors]);
  return (
    <form>
      <Grid container spacing={2} sx={{flexGrow: 1, py: '24px'}}>
        <Grid xs={12} item>
          <Typography variant={'body1'}>Основна інформація</Typography>
        </Grid>
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.last_time_contacted_at_from}
          gridSize={3}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.last_time_contacted_at_to}
          gridSize={3}
        />
        {isBase && (
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields.author_id}
            gridSize={3}
            items={assignerList.map((item) => ({label: `${item.first_name} ${item.last_name}`, value: item.id}))}
          />
        )}
        <Grid xs={12} item>
          <Divider sx={{py: '12px', mb: '12px'}} />
        </Grid>
        <Grid xs={12} item>
          <Typography variant={'body1'}>Інформація про пропозицію</Typography>
        </Grid>
        <SourceSelect control={control} register={register} gridSize={3} />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.source_id}
          rules={{
            pattern: {
              value: /^\d+$/,
              message: 'Поле повинно бути цілим числом.'
            }
          }}
          gridSize={3}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.property_id}
          gridSize={3}
          rules={{
            pattern: {
              value: /^\d+$/,
              message: 'Поле повинно бути цілим числом.'
            }
          }}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.estate_type}
          gridSize={3}
          items={getNormalisedOptions(eastTypeNames)}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.price_from}
          gridSize={3}
          onChange={onPriceInputChange}
          showEndAdornment={true}
          rules={{
            pattern: {
              value: /^\d+$/,
              message: 'Поле повинно бути цілим числом.'
            }
          }}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.price_to}
          gridSize={3}
          onChange={onPriceInputChange}
          showEndAdornment={true}
          rules={{
            pattern: {
              value: /^\d+$/,
              message: 'Поле повинно бути цілим числом.'
            }
          }}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.offer_type}
          gridSize={3}
          items={getNormalisedOptions(offerTypeNames)}
        />
        <Grid xs={12} item>
          <Divider sx={{py: '12px', mb: '12px'}} />
        </Grid>
        <Grid xs={12} item>
          <Typography variant={'body1'}>Інформація про обʼєкт</Typography>
        </Grid>
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.cities}
          gridSize={3}
          items={cities.map((item) => ({
            value: item.code,
            label: item.name
          }))}
          multiple={true}
        />
        <FieldWidget
          control={control}
          register={register}
          items={[]}
          filedName={FormFields.river}
          gridSize={3}
          disabled={true}
        />
        <FieldWidget
          control={control}
          register={register}
          items={[]}
          filedName={FormFields.underground}
          gridSize={3}
          disabled={true}
        />
        <FieldWidget
          control={control}
          register={register}
          items={[]}
          filedName={FormFields.smallRegion}
          gridSize={3}
          disabled={true}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.districts}
          gridSize={3}
          items={districts.map((item) => ({
            value: item.id,
            label: item.name
          }))}
          multiple={true}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.street_name_autocomplete}
          gridSize={3}
          items={districts.map((item) => ({
            value: item.id,
            label: item.name
          }))}
          multiple={false}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.newly_built}
          gridSize={3}
          items={[
            {label: 'Новобудова', value: 0},
            {label: 'Вторинка', value: 1}
          ]}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.residential_complexes}
          gridSize={3}
          items={residentialComplex.map((item) => ({
            value: item.id,
            label: item.name
          }))}
          className={classNames(styles.hide, {
            [styles.show]: displayBuildingTypesSelect === 0
          })}
          multiple={true}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.building_types}
          gridSize={3}
          items={buildingType.map((item) => ({
            value: item.id,
            label: item.name
          }))}
          className={classNames(styles.hide, {
            [styles.show]: displayBuildingTypesSelect === 1
          })}
          multiple={true}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.rooms}
          gridSize={3}
          items={propertyFiltersRoom}
          multiple={true}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.living_area_from}
          gridSize={1.5}
          rules={{
            pattern: {
              value: /^\d+$/,
              message: 'Поле повинно бути цілим числом.'
            }
          }}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.living_area_to}
          gridSize={1.5}
          rules={{
            pattern: {
              value: /^\d+$/,
              message: 'Поле повинно бути цілим числом.'
            }
          }}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.floor_from}
          gridSize={1.5}
          rules={{
            pattern: {
              value: /^\d+$/,
              message: 'Поле повинно бути цілим числом.'
            }
          }}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.floor_to}
          gridSize={1.5}
          rules={{
            pattern: {
              value: /^\d+$/,
              message: 'Поле повинно бути цілим числом.'
            }
          }}
        />
        {!isOwnerFilters && isParser && (
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields.assignee_id}
            gridSize={3}
            items={assignerList.map((item) => ({label: `${item.first_name} ${item.last_name}`, value: item.id}))}
          />
        )}
        <Grid xs={12} item>
          <Divider sx={{py: '12px', mb: '12px'}} />
        </Grid>
        <Grid xs={12} item>
          <Typography variant={'body1'}>Контактна інформація</Typography>
        </Grid>
        <FieldWidget control={control} register={register} filedName={FormFields.name} gridSize={3} />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.role}
          gridSize={3}
          items={getNormalisedOptions(propertyContactRole)}
          multiple={true}
        />
        <FieldWidget control={control} register={register} filedName={FormFields.phone_numbers} gridSize={3} />
        <Grid xs={12} item>
          <Divider sx={{py: '12px', mb: '12px'}} />
        </Grid>
        <Grid xs={12} item>
          <Typography variant={'body1'}>Додаткова інформація</Typography>
        </Grid>
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.children}
          gridSize={3}
          items={getNormalisedOptions(childrenAllowedStatus)}
          multiple={true}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.pets}
          gridSize={3}
          items={getNormalisedOptions(petsAllowedStatus)}
          multiple={true}
        />
        <Grid xs={6} item>
          <Box sx={{display: 'flex', gap: '12px', alignItems: 'flex-end', height: '100%', justifyContent: 'flex-end'}}>
            <Button
              variant={'contained'}
              color={'primary'}
              type={'submit'}
              onClick={handleSubmit(onApplyClick)}
              disabled={Object.keys(formStateErrors).length > 0 || isLoading}
              className={styles.btn}
            >
              {isLoading ? <CircularProgress size={'20px'} /> : <>Застосувати</>}
            </Button>
            <Button
              variant={'contained'}
              color={'dark'}
              type={'button'}
              className={styles.btn}
              onClick={() => {
                reset();
              }}
            >
              Скинути всі
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default ParserFilters;
