import {useEffect} from 'react';

import {useAuth} from '../../../hooks/useAuth';

const SignOutPage = () => {
  const {logout} = useAuth();

  useEffect(() => {
    // Call the logout function from the useAuth hook
    logout();
    // Redirect the user to the homepage after logging out
  }, [logout]);

  return (
    <div>
      <h2>Ви вийшли з облікового запису</h2>
    </div>
  );
};

export default SignOutPage;
