import {Box, FormControl, FormHelperText, Typography} from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';
import {Controller} from 'react-hook-form';
export type InputSizes = 'small' | 'medium' | 'large';

const TextAreaField: React.FC<{
  control: any;
  onChange?: (name: string, value: string) => void;
  label?: string;
  name: string;
  placeholder?: string;
  register?: any;
  sx?: Record<string, any>;
  rules?: Record<string, any>;
  clearErrors?: (name: string) => void;
  disabled?: boolean;
}> = ({control, onChange, label, name, placeholder = '', rules = {}, disabled}) => {
  return (
    <FormControl fullWidth>
      <Typography variant="caption" gutterBottom>
        {label}
      </Typography>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({field, fieldState, formState}) => {
          return (
            <>
              <Box sx={{bgcolor: 'background.label'}} className={styles.areaWrapper}>
                <textarea
                  {...field}
                  rows={2}
                  disabled={disabled}
                  onChange={(event) => {
                    field.onChange(event);
                  }}
                  placeholder={placeholder}
                />
              </Box>

              {!!formState.errors[name]?.message && (
                <FormHelperText sx={{color: 'error.main'}}>
                  <>
                    {
                      //@ts-ignore
                      formState.errors[name]?.message || formState?.errors[name]?.['0']
                    }
                  </>
                </FormHelperText>
              )}
            </>
          );
        }}
      />
    </FormControl>
  );
};

export default TextAreaField;
