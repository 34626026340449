import React from 'react';
import {ReactComponent as Triangle} from 'svg-icons/triangle.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';

const SortIcon: React.FC<{direction: 'asc' | 'desc'; active: boolean}> = ({direction, active}) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={classNames({
          [styles.opacity]: direction === 'asc' || !active
        })}
      >
        <Triangle />
      </div>
      <div
        className={classNames(styles.second, {
          [styles.opacity]: direction === 'desc' || !active
        })}
      >
        <Triangle />
      </div>
    </div>
  );
};

export default SortIcon;
