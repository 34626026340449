import React, {ChangeEvent, FormEvent, useState} from 'react';

import {CardContent, TextField, Button, Stack, Typography} from '@mui/material';
import {login as loginRequest} from 'api/auth';
import BoxCard from 'components/common/box-card/BoxCard';
import {useAuth} from 'hooks/useAuth';

export type ILoginValues = {
  email: string;
  password: string;
};

export type ILoginResponse = {
  access_token: string;
  token_type: string;
  expires_in: string;
};

const SignInPage = () => {
  const {login} = useAuth();
  const [formData, setFormData] = useState<ILoginValues>({
    email: '',
    password: ''
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
    setErrorMessage('');
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await loginRequest({
        email: formData.email,
        password: formData.password
      });

      login(response.access_token);
    } catch (error) {
      setErrorMessage('Неправильний логін або пароль');
    }
  };

  return (
    <div>
      <BoxCard title="Увійти">
        <CardContent>
          {errorMessage && (
            <Typography color="error" sx={{pb: 3}}>
              {errorMessage}
            </Typography>
          )}
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                label="Логін"
                variant="outlined"
                fullWidth
                type="text"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Пароль"
                variant="outlined"
                fullWidth
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
              <Button variant="contained" type="submit" fullWidth>
                Увійти
              </Button>
            </Stack>
          </form>
        </CardContent>
      </BoxCard>
    </div>
  );
};

export default SignInPage;
