import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputBase,
  MenuItem,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material';
import React, {useMemo} from 'react';
import styles from './styles.module.scss';
import {Controller} from 'react-hook-form';
import classNames from 'classnames';

export type InputSizes = 'small' | 'medium' | 'large';

const InputField: React.FC<{
  control: any;
  onChange: (name: string, value: string) => void;
  label?: string;
  name: string;
  adornmentStart?: string;
  showEndAdornment?: boolean;
  size: InputSizes;
  placeholder?: string;
  register?: any;
  sx?: Record<string, any>;
  rules?: Record<string, any>;
  clearErrors?: (name: string) => void;
  disabled?: boolean;
}> = ({
  control,
  onChange,
  label,
  name,
  adornmentStart = '',
  size = '',
  placeholder = '',
  register,
  sx = {},
  showEndAdornment = false,
  rules = {},
  disabled
}) => {
  const EndAdornmentComponent = useMemo(() => {
    return (
      <InputAdornment position="start" className={classNames(styles.wrapper)}>
        <Controller
          control={control}
          render={({field, fieldState, formState}) => {
            return <InputBase {...field} disabled={true}></InputBase>;
          }}
          name={`adornment_${name}`}
        />
      </InputAdornment>
    );
  }, [showEndAdornment]);

  const startAdornment = !!adornmentStart
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <Typography variant={'body2'}>{adornmentStart}</Typography>
          </InputAdornment>
        )
      }
    : {};

  const endAdornment = showEndAdornment
    ? {
        endAdornment: EndAdornmentComponent
      }
    : {};

  return (
    <FormControl className={styles[size]} fullWidth>
      <Typography variant="caption" gutterBottom>
        {label}
      </Typography>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({field, fieldState, formState}) => {
          const hasError = Object.keys(formState.errors).length > 0;
          let errorMessage = '';
          if (hasError) {
            if (name === 'price_to') {
              //@ts-ignore
              errorMessage = formState.errors['price']?.to?.message;
            } else if (name === 'price_from') {
              //@ts-ignore
              errorMessage = formState.errors['price']?.from?.message;
            } else if (name === 'pets') {
              //@ts-ignore
              errorMessage = formState.errors['pets']?.message;
            } else if (name === 'children') {
              //@ts-ignore
              errorMessage = formState.errors['children']?.message;
            } else if (name === 'room') {
              //@ts-ignore
              errorMessage = formState.errors['room']?.message;
            } else if (name === 'living_area_from') {
              //@ts-ignore
              errorMessage = formState.errors['living_area']?.from?.message;
            } else if (name === 'living_area_to') {
              //@ts-ignore
              errorMessage = formState.errors['living_area']?.to?.message;
            } else if (name === 'floor_to') {
              //@ts-ignore
              errorMessage = formState.errors['floor']?.to?.message;
            } else if (name === 'floor_from') {
              //@ts-ignore
              errorMessage = formState.errors['floor']?.from?.message;
            }
          }

          return (
            <>
              <OutlinedInput
                sx={sx}
                {...field}
                disabled={disabled}
                onChange={(event) => {
                  field.onChange(event);
                  onChange(name, event.target.value);
                }}
                {...startAdornment}
                {...endAdornment}
                placeholder={placeholder}
              />
              {(errorMessage || !!formState.errors[name]?.message) && (
                <FormHelperText sx={{color: 'error.main'}}>
                  <>
                    {formState.errors[name]?.message
                      ? formState.errors[name]?.message
                      : //@ts-ignore
                        errorMessage || formState?.errors[name]?.['0']}
                  </>
                </FormHelperText>
              )}
            </>
          );
        }}
      />
    </FormControl>
  );
};

export default InputField;
