import {Box, IconButton, Snackbar, Typography} from '@mui/material';
import styles from './styles.module.scss';
import React from 'react';
import {SnackbarCloseReason} from '@mui/material/Snackbar/Snackbar';
import {ReactComponent as PartyIcon} from 'svg-icons/party.svg';
import {ReactComponent as CloseIcon} from 'svg-icons/close.svg';
import {ReactComponent as Octagon} from 'svg-icons/octagon-alert.svg';
import {ReactComponent as Triangle} from 'svg-icons/triangle-alert.svg';

const Toast: React.FC<{
  open: boolean;
  setOpen: (value: boolean) => void;
  type: 'success' | 'error' | 'warning';
  message: {
    title: string;
    body: string;
  };
}> = ({open, setOpen, type, message}) => {
  const handleClose = (event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
      <Box sx={{bgcolor: 'background.default', borderColor: `background.${type}`}} className={styles.snackWrapper}>
        <Box className={styles.barRow}>
          {type === 'success' && <PartyIcon />}
          {type === 'error' && <Octagon />}
          {type === 'warning' && <Triangle />}

          <Typography variant={'h6'}>
            <strong>{message.title}</strong>
          </Typography>
          <IconButton
            type={'button'}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={styles.barRow}>
          <Typography sx={{fontSize: '0.75rem'}}>{message.body}</Typography>
        </Box>
      </Box>
    </Snackbar>
  );
};

export default Toast;
