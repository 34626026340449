import {useQuery} from 'react-query';
import {getDistrict} from '../api/filters';

const useDistricts = () => {
  const {data: districts = [], isLoading: isDistrictsLoading} = useQuery(
    'locations/districtsAutocomplete',
    getDistrict,
    {
      retry: false
    }
  );

  return {districts, isDistrictsLoading};
};

export default useDistricts;
