import React from 'react';
import {CardMedia} from '@mui/material';
import {IPropertyMedia} from 'types';
import LiteboxSlider from 'components/common/liteboxSlider';
import styles from './styles.module.scss';

const Gallery: React.FC<{media: IPropertyMedia[]}> = ({media}) => {
  const [{open, slideIndex}, setOpen] = React.useState({open: false, slideIndex: 0});
  return (
    <div className={styles.wrapper}>
      {media.map((image: IPropertyMedia, index) => (
        <CardMedia
          onClick={() =>
            setOpen((state) => ({
              open: true,
              slideIndex: index
            }))
          }
          component="img"
          image={image.url}
          alt={`Image`}
          sx={{width: 64, height: 64}}
        />
      ))}
      <LiteboxSlider images={media} isOpen={open} setIsOpen={setOpen} index={slideIndex} />
    </div>
  );
};

export default Gallery;
