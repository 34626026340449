import {FormControl, Input, OutlinedInput, Typography} from '@mui/material';
import React from 'react';
import {IMaskInput} from 'react-imask';
import styles from '../inputField/styles.module.scss';
import {Controller} from 'react-hook-form';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props;
  return (
    <IMaskInput
      {...other}
      mask="(000) 000-00-00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({target: {name: props.name, value}})}
      overwrite
    />
  );
});

const InputMask = ({control, onChange, label, name, adornmentStart = '', size = '', placeholder = ''}) => {
  return (
    <FormControl variant="standard" className={styles[size]}>
      <Typography variant="caption" gutterBottom>
        {label}
      </Typography>
      <Controller
        control={control}
        render={({field, fieldState, formState}) => {
          return (
            <OutlinedInput
              {...field}
              onChange={(event) => {
                field.onChange(event);
              }}
              name={name}
              inputComponent={TextMaskCustom}
              placeholder={'(000) 000-00-00'}
            />
          );
        }}
        name={name}
      />
    </FormControl>
  );
};

export default InputMask;
