import {Box, Card, Divider, Grid, Link, Typography} from '@mui/material';
import {ImageCarousel} from 'components/common/imageCarousel';
import {ReactComponent as TrendUp} from 'svg-icons/trendUp.svg';
import {ReactComponent as TrendDown} from 'svg-icons/trend-down.svg';
import styles from './styles.module.scss';
import classNames from 'classnames';
import ItemActions from './item-actions';
import PropertyItemFooter from './item-footer';
import React from 'react';
import {PropertyLabelsType, PropertyListItemType, PublicationStatus} from 'types';
import ItemsFeature from './items-feature';
import {currencySymbols} from 'constants/index';
import ItemContacts from './item-contacts';
import PropertyHeader from '../property-header';
import PropertyAddress from '../property-address';
import NoImage from '../no-image';
import {formatNumber} from '../../../helpers';
import {useParams} from 'react-router-dom';

const PropertyItem: React.FC<{
  item: PropertyListItemType;
  filter: any;
  refetch: any;
  parentList: 'base' | 'parser';
}> = ({
  item: propertyItem,
  parentList,
  item: {
    address,
    features,
    media,
    price: {original, converted},
    source,
    contacts,
    latest_publication,
    labels,
    publication_status,
    status
  },
  refetch
}) => {
  const {filter: filterName = ''} = useParams();
  const getBaseFilters = () => {
    switch (filterName) {
      case 'active':
        return ['edit', 'comment', 'archive', 'trash'];
      case 'unpublished':
        return publication_status === PublicationStatus.UNPUBLISHED
          ? ['published', 'doNotPublish', 'edit', 'comment', 'archive', 'trash']
          : ['edit', 'doNotPublish', 'comment', 'archive', 'trash'];
      default:
        return ['edit', 'comment', 'revivedFromArchive', 'trash'];
    }
  };
  const actionsList = parentList === 'base' ? getBaseFilters() : ['putOff', 'merge', 'edit', 'postponed', 'trash'];
  const priceLower = labels?.filter((item) => item.label.alias === PropertyLabelsType.PRICE_LOWER);
  const priceHigher = labels?.filter((item) => item.label.alias === PropertyLabelsType.PRICE_HIGHER);

  return (
    <>
      <Grid item className={styles.itemWrapper}>
        <Card sx={{bgcolor: 'background.secondary', px: '12px'}}>
          <Box className={styles.box}>
            <PropertyHeader sources={source} title={propertyItem.title} type={propertyItem.estate_type} />
            <Box className={classNames(styles.box, styles.auto)}>
              <Typography variant="body2" sx={{color: 'text.primary'}}>
                Ціна:
              </Typography>
              {!!priceHigher?.length && <TrendUp />}
              {!!priceLower?.length && <TrendDown />}
              <Typography variant="body2" sx={{color: 'text.primary', fontWeight: 700}}>
                {converted.amount ? <>{formatNumber(converted.amount)} грн</> : `-`}
              </Typography>
              <Typography variant="body2">
                ({currencySymbols[original.currency_code]} {formatNumber(original.amount)})
              </Typography>
            </Box>
          </Box>
          <Divider sx={{mx: '-12px'}} />
          <Grid container spacing={4} sx={{py: '12px'}}>
            <Grid item className={styles.column}>
              <Box className={styles.relative}>
                {media?.length ? (
                  <ImageCarousel imageList={media.length ? media : []} />
                ) : (
                  <Box className={styles.noImage}>
                    <NoImage />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item className={styles.column}>
              <Divider orientation={'vertical'} className={styles.divider} component={'div'} />
              <ItemsFeature features={features} address={address} />
            </Grid>
            <Grid item className={styles.column}>
              <Divider orientation={'vertical'} className={styles.divider} component={'div'} />
              <Box className={styles.row}>
                <PropertyAddress address={address} />
              </Box>
            </Grid>
            <Grid item className={styles.column}>
              <Divider orientation={'vertical'} className={styles.divider} component={'div'} />
              <ItemContacts contacts={contacts} />
            </Grid>
            <Grid item className={classNames(styles.column, styles.maxW)}>
              <Divider orientation={'vertical'} className={styles.divider} component={'div'} />
              <ItemActions
                assignee={propertyItem.assignee}
                propertyId={propertyItem.id}
                actionList={actionsList}
                refetch={refetch}
                parentList={parentList}
                propertyStatus={status}
              />
            </Grid>
          </Grid>
          <Divider sx={{mx: '-12px'}} />
          <PropertyItemFooter
            propertyId={propertyItem?.id}
            lastTimeContactedAt={propertyItem?.last_time_contacted_at}
            createdAt={propertyItem?.created_at}
            latestPublicationAt={latest_publication?.posted_at}
            sources={source}
            contacts={contacts}
            labels={labels}
            offerType={propertyItem.offer_type}
          />
        </Card>
      </Grid>
    </>
  );
};

export default PropertyItem;
