import React, {useMemo} from 'react';
import {Box, Typography} from '@mui/material';
import styles from './styles.module.scss';
import {Children, EstateAddress, Pets, RawFeatures, RawFeaturesAlias} from 'types';
import {childrenAllowedStatus, petsAllowedStatus} from 'constants/index';

const getFeaturesValue = (alias: RawFeaturesAlias, featuresList: RawFeatures[]) => {
  const feature = featuresList.find((item) => item.alias === alias);
  return feature ? feature.value : null;
};

const ItemsFeature: React.FC<{features: RawFeatures[]; address: EstateAddress}> = ({features, address}) => {
  const livingRoom = useMemo(() => {
    const rooms = getFeaturesValue(RawFeaturesAlias.rooms, features);
    const area = getFeaturesValue(RawFeaturesAlias.living_area, features);
    const getRoomsText = () => {
      if (rooms && +rooms > 1 && +rooms < 5) {
        return `${rooms} кімнати`;
      }
      if (rooms && +rooms > 5) {
        return `${rooms} кімнат`;
      }
      return `${rooms} кімната`;
    };

    return `${getRoomsText()} ${area ? '(' + area + 'м²)' : ''}`;
  }, [features]);

  const floor = useMemo(() => {
    const firstValue = getFeaturesValue(RawFeaturesAlias.floor, features);
    const secondValue = getFeaturesValue(RawFeaturesAlias.total_floors, features);
    if (!firstValue) {
      return '';
    }
    return `${firstValue}${secondValue ? '/' + secondValue : ''}`;
  }, [features]);

  const childrenAllowed = useMemo(() => {
    const status = getFeaturesValue(RawFeaturesAlias.children, features);

    if (!status) {
      return '';
    }

    const statusString: string = childrenAllowedStatus[status as Children];

    return statusString;
  }, [features]);

  const petsAllowed = useMemo(() => {
    const status = getFeaturesValue(RawFeaturesAlias.pets, features);

    if (!status) {
      return '';
    }

    const statusString: string = petsAllowedStatus[status as Pets];

    return statusString;
  }, [features]);

  const featuresToRender = [
    {
      name: 'Новобудова:',
      value: !!address.newly_built ? 'Так' : 'Ні'
    },
    {
      name: 'Кімнати:',
      value: livingRoom || '-'
    },
    {
      name: 'Поверх:',
      value: floor || '-'
    },
    {
      name: 'Діти:',
      value: childrenAllowed || '-'
    },
    {
      name: 'Тварини:',
      value: petsAllowed || '-'
    }
  ];
  return (
    <>
      {featuresToRender.map(({name, value}, i) => (
        <Box className={styles.row} key={i}>
          <Typography variant={'subtitle1'}>{name}</Typography>
          <Typography variant={'subtitle2'}>{value}</Typography>
        </Box>
      ))}
    </>
  );
};

export default ItemsFeature;
